import history from 'react/utils/history';
import {
	SENDING_REQUEST,
	LOADING_REQUEST,
	OPEN_ALERT,
	CLOSE_ALERT,
} from 'react/constants/utils';

let lastNoteType = '';

/**
 * Forwards the user
 * @param {string} location The route the user should be forwarded to
 */
export function forwardTo(location) {
	return () => history.push(location);
}

/**
 * Removes the last error from the form
 */
export function removeLastFormNote(selector) {
	const form = document.querySelector(selector);
	form.classList.remove(`js-form__note--${lastNoteType}`);
}

/**
 * Called when a request failes
 * @param  {object} note An object containing information about the error
 * @param  {string} note.type The js-form__note + note.type class will be set on the form
 */
export function requestNoted(selector, note) {
	// Remove the class of the last error so there can only ever be one
	removeLastFormNote(selector);
	const form = document.querySelector(selector);
	// And add the respective classes
	form.classList.add('js-form__note');
	form.classList.add('js-form__note-animation');
	form.classList.add(`js-form__note--${note.type}`);
	lastNoteType = note.type;
	// Remove the animation class after the animation is finished, so it
	// can play again on the next error
	setTimeout(() => {
		form.classList.remove('js-form__note-animation');
	}, 150);
}
/**
 * Checks if any elements of a JSON object are empty
 * @param  {object} elements The object that should be checked
 * @return {boolean}         True if there are empty elements, false if there aren't
 */
export function anyElementsEmpty(elements) {
	return Object.keys(elements).some(key => !elements[key]);
}

/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} sending The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function sendingRequest(sending) {
	return { type: SENDING_REQUEST, sending };
}

/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} loading The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function loadingRequest(loading) {
	return { type: LOADING_REQUEST, loading };
}

/**
 * Sets the alert state, close the alert modal
 * @return {object}          Formatted action for the reducer to handle
 */
export function closeAlert() {
	return { type: CLOSE_ALERT };
}

/**
 * Sets the alert state, which displays a alert modal with message
 * @param  {string} message The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function openWarningAlert(message) {
	return {
		type: OPEN_ALERT,
		alert: { type: 'warning', message },
	};
}
