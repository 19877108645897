import {
	SET_TARGET,
	SET_LIST,
	SET_STATUS,
	SET_STORE,
	SET_QUERY,
} from 'react/constants/order';
import routePath from 'react/constants/path';
import orderReq from 'react/request/order';
import { examOrder } from 'react/utils/exam';

import { loadingRequest, forwardTo, openWarningAlert } from './utils';

export function setOrderList(orders) {
	return { type: SET_LIST, orders };
}

export function setOrderStatus(status) {
	return { type: SET_STATUS, status };
}

export function setOrderStore(store) {
	return { type: SET_STORE, store };
}

export function setTargetOrder(order) {
	return { type: SET_TARGET, order };
}

export function setQuery(q) {
	return { type: SET_QUERY, q };
}

export function initialTarget() {
	return dispatch => {
		const initial = {
			_id: '',
			creator: '',
			serial: '',
			batch: '',
			status: '',
			store: '',
			items: [],
			discount: '',
			discountTax: '',
			price: '',
			total: '',
			totalTax: '',
			note: '',
			shipment: {},
			payment: {},
			invoicing: false,
			invoice: {},
			costType: 'BASED',
		};
		dispatch(setTargetOrder(initial));
	};
}

export function fetchOrderList() {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { success, orders } = await orderReq.fetchList();
		if (success) {
			dispatch(setOrderList(orders));
		}
		dispatch(loadingRequest(false));
	};
}

export function fetchTargetOrder(id) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { success, order } = await orderReq.fetchTarget(id);
		if (success) {
			dispatch(setTargetOrder(order));
		}
		dispatch(loadingRequest(false));
	};
}

export function queryOrder(q) {
	return async dispatch => {
		dispatch(loadingRequest(true));
		dispatch(setQuery(q));

		const { success, orders } = await orderReq.queryTarget(q);
		if (success) {
			dispatch(setOrderList(orders));
		}
		dispatch(loadingRequest(false));
	};
}

export function createOrder(order) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const failList = examOrder(order);
		if (failList.length > 0) {
			dispatch(loadingRequest(false));
			return dispatch(openWarningAlert(failList.join('\n')));
		}

		const { success } = await orderReq.create(order);
		if (success) {
			dispatch(forwardTo(routePath.orderRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function updateOrder(order) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const updateData = {
			status: order.status,
			store: order.store,
			items: order.items,
			discount: order.discount,
			discountTax: order.discountTax,
			price: order.price,
			total: order.total,
			totalTax: order.totalTax,
			note: order.note,
			shipment: order.shipment,
			payment: order.payment,
			invoicing: order.invoicing,
			invoice: order.invoice,
		};

		const { success } = await orderReq.update(order._id, updateData);
		if (success) {
			dispatch(forwardTo(routePath.orderRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function updateGriddle(order) {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { order: { query } } = getState();
		const updateData = {
			shipment: order.shipment,
			invoice: order.invoice,
			listnote: order.listnote,
		};

		const { success } = await orderReq.update(order._id, updateData);
		if (success) {
			dispatch(queryOrder(query));
		}
		dispatch(loadingRequest(false));
	};
}

export function updateShipment(order) {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { order: { query } } = getState();
		const updateData = {
			shipment: order.shipment,
		};
		const { success } = await orderReq.goship(order._id, updateData);
		if (success) {
			dispatch(queryOrder(query));
		}
		dispatch(loadingRequest(false));
	};
}

export function updatePaid(order) {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { order: { query } } = getState();
		const updateData = {
			payment: order.payment,
		};
		console.log(updateData);

		const { success } = await orderReq.update(order._id, updateData);
		if (success) {
			dispatch(queryOrder(query));
		}
		dispatch(loadingRequest(false));
	};
}

export function changeOrderStatus(ids, status) {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { order: { query } } = getState();
		const postData = { status };
		const successes = await Promise.all(ids.map(id => orderReq.update(id, postData)));
		if (successes.some(s => s.success)) {
			dispatch(queryOrder(query));
		}
		dispatch(loadingRequest(false));
	};
}

export function exportOrderStickers(ids) {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { order: { query } } = getState();
		await orderReq.exportStickers(ids);
		dispatch(queryOrder(query));
		dispatch(loadingRequest(false));
	};
}

export function downloadOrder(ids) {
	return async dispatch => {
		dispatch(loadingRequest(true));
		await orderReq.exportOrder(ids);
		dispatch(loadingRequest(false));
	};
}

export function removeOrder(ids) {
	return async (dispatch, getState) => {
		if (ids.length <= 0) {
			return;
		}
		const { order: { query } } = getState();
		dispatch(loadingRequest(true));
		const successes = await Promise.all(ids.map(id => orderReq.delete(id)));
		if (successes.some(s => s.success)) {
			dispatch(queryOrder(query));
		}
		dispatch(loadingRequest(false));
	};
}
