/**
 *
 * AdminDashboard.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

// Import stuff
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import routePath from 'react/constants/path';
import history from 'react/utils/history';

// exam the authority on enter the dashboard
import { useMedia } from 'react/utils/event';
import { useBoolean } from 'react/utils/hook';
import auth from 'react/utils/auth';
import { clearGlobalModal } from 'react/actions/modal';

import DashboardSideNav from 'react/components/DashboardNav';
import LoadingDimmer from 'react/components/Utils/LoadingDimmer';
import AlertModal from 'react/components/Utils/Modal/AlertModal';

const AdminDashboard = ({
	location: { pathname, search },
	history: propHistory,
	loading,
	children,
}) => {
	const media = useMedia();
	const [navshow, { toggle }] = useBoolean({ defaultBoolean: media !== 'phone' });

	useEffect(() => {
		const query = new URLSearchParams(search);
		if (query.get('iam')) {
			auth.iam(query.get('iam'));
		}
		if (pathname !== routePath.login && pathname !== routePath.adminconfirm && !auth.loggedIn()) {
			history.push(`${routePath.login}${search}`);
		}
	}, []);

	const noNaviPages = [routePath.login, routePath.adminconfirm];

	return (
		<div>
			{
				!noNaviPages.some(p => p === pathname) &&
				<DashboardSideNav
					show={navshow}
					history={propHistory}
					onToggleNav={toggle}
				/>
			}
			<div className={`page ${(noNaviPages.some(p => p === pathname) || !navshow) && 'nonavi'}`}>
				{loading ? <LoadingDimmer /> : null}
				{children}
			</div>
			<AlertModal />
		</div>
	);
};

// Which props do we want to inject, given the global state?
function mapStateToProps({
	utils: { currentlyLoading },
}) {
	return {
		loading: currentlyLoading,
	};
}

function mapDispatchToProps(dispatch) {
	return ({
		onRouteChange: () => dispatch(clearGlobalModal()),
	});
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));
