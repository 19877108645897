exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__breadcrumbs___2d_EO{margin-top:10px;margin-left:-30px}.styles__preview___21tlu{position:absolute;width:100px;height:50px;top:0;right:calc(3% + 30px)}.styles__content___3JhZE{width:100%;height:auto;min-height:500px;padding:3%}.styles__content___3JhZE>.styles__status___17lmd{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100px;padding:10px;margin:20px 0;border:1px solid #eee;box-shadow:0 0 5px 0 rgba(0,0,0,.07)}@media screen and (max-width:719px){.styles__content___3JhZE{padding:0}}", ""]);

// exports
exports.locals = {
	"breadcrumbs": "styles__breadcrumbs___2d_EO",
	"preview": "styles__preview___21tlu",
	"content": "styles__content___3JhZE",
	"status": "styles__status___17lmd"
};