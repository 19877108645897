import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const OrderCreate = Loadable({
	loader() {
		return import('react/components/Pages/Order/Create').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const OrderEdit = Loadable({
	loader() {
		return import('react/components/Pages/Order/Edit').then(object => object.default);
	},
	loading: LoadingDimmer,
});
