import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const StorePage = Loadable({
	loader() {
		return import('react/components/Pages/Store').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const StoreCreate = Loadable({
	loader() {
		return import('react/components/Pages/Store/Create').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const StoreEdit = Loadable({
	loader() {
		return import('react/components/Pages/Store/Edit').then(object => object.default);
	},
	loading: LoadingDimmer,
});
