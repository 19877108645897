exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__info-card___2Vqt3{float:left;width:160px;height:80px;padding:20px;font-size:20px}.styles__info-card___2Vqt3>.styles__right___1dDJp{float:right}.styles__info-card___2Vqt3>.styles__right___1dDJp>.styles__number___2zfRm{font-size:25px;font-weight:500;color:#e4007f;line-height:1em}.styles__info-card___2Vqt3>.styles__right___1dDJp>.styles__topic___23SzK{font-size:15px;color:#777;line-height:1.5em}", ""]);

// exports
exports.locals = {
	"info-card": "styles__info-card___2Vqt3",
	"infoCard": "styles__info-card___2Vqt3",
	"right": "styles__right___1dDJp",
	"number": "styles__number___2zfRm",
	"topic": "styles__topic___23SzK"
};