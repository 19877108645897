exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__menu___3DMM2{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;position:relative;width:100%;height:50px;padding:5px 20px;cursor:pointer}.styles__menu___3DMM2>img{width:40px;height:40px;background-color:#eee;border:2px solid #eee;border-radius:50%}.styles__menu___3DMM2>.styles__info___12Lti{margin:0 10px;width:140px}.styles__menu___3DMM2>.styles__info___12Lti>p{color:#eee}.styles__menu___3DMM2>.styles__info___12Lti>span{font-size:12px;color:#aaa}.styles__menu___3DMM2>i{font-size:20px;line-height:40px;color:#e4007f}.styles__menu___3DMM2>.styles__popover___3B4TX{display:none;position:absolute;width:160px;left:140px;top:45px;background-color:#fefefe;border:1px solid #eee;border-radius:5px;box-shadow:0 5px 10px 0 rgba(0,0,0,.04);font-size:15px;padding:10px 15px;z-index:100}.styles__menu___3DMM2>.styles__popover___3B4TX:before{border-bottom:7px solid #fefefe;border-right:7px solid transparent;border-left:7px solid transparent;top:-7px;content:\"\";display:block;left:50%;margin-left:-7px;position:absolute}.styles__menu___3DMM2>.styles__popover___3B4TX>a{display:block;color:#777;line-height:1.8em}.styles__menu___3DMM2>.styles__popover___3B4TX>a:hover{color:#333}.styles__menu___3DMM2>.styles__popover___3B4TX>.styles__divider___FvZ_q{height:5px;margin:3px 0;border-top:1px solid #eee}.styles__menu___3DMM2.styles__dropdown___oRChF>.styles__popover___3B4TX{display:block;-webkit-animation:styles__fade-in___3uS5t .3s linear 1,styles__move-up___M6ba5 .3s linear 1;-moz-animation:styles__fade-in___3uS5t .3s linear 1,styles__move-up___M6ba5 .3s linear 1;-ms-animation:styles__fade-in___3uS5t .3s linear 1,styles__move-up___M6ba5 .3s linear 1}", ""]);

// exports
exports.locals = {
	"menu": "styles__menu___3DMM2",
	"info": "styles__info___12Lti",
	"popover": "styles__popover___3B4TX",
	"divider": "styles__divider___FvZ_q",
	"dropdown": "styles__dropdown___oRChF",
	"fade-in": "styles__fade-in___3uS5t",
	"fadeIn": "styles__fade-in___3uS5t",
	"move-up": "styles__move-up___M6ba5",
	"moveUp": "styles__move-up___M6ba5"
};