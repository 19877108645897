import _ from 'lodash';
import auth from 'react/utils/auth';

import { API_ENDPOINT } from '../../config';

function processStatus(response) {
	if (response.ok) {
		return response.json().then(res =>
			Promise.resolve(_.extend(res, {
				status: response.status,
			})));
	}
	return Promise.resolve({
		success: false,
		status: response.status,
	});
}

function resposePackage(jsonRes) {
	if (jsonRes.success) {
		return Promise.resolve(jsonRes.extras);
	}
	return Promise.reject({
		status: jsonRes.status,
		message: jsonRes.extras ? jsonRes.extras.msg : 0,
	});
}

/* Easy fetch is xhr for GET, DELETE..., request without body */
export const easyFetch = async (url, method, query) => {
	const options = { method, credentials: 'same-origin' };
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	return fetch(requestUri, options).then(processStatus).catch(err => {
		console.log(`There has been a problem with your fetch operation: ${err.message}`);
	}).then(resposePackage);
};

export const authEasyFetch = async (url, method, query) => {
	const options = {
		method,
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${auth.token()}`,
		},
	};
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	return fetch(requestUri, options).then(processStatus).catch(err => {
		console.log(`There has been a problem with your fetch operation: ${err.message}`);
	}).then(resposePackage);
};

/* Body fetch is xhr for POST, PUT..., request with body */
export const bodyFetch = async (url, method, postData, query) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'same-origin',
		method,
		body: JSON.stringify(postData),
	};
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	return fetch(requestUri, options).then(processStatus).catch(err => {
		console.log(`There has been a problem with your fetch operation: ${err.message}`);
	}).then(resposePackage);
};

export const authBodyFetch = async (url, method, postData, query) => {
	const options = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${auth.token()}`,
		},
		credentials: 'same-origin',
		body: JSON.stringify(postData),
	};
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	return fetch(requestUri, options).then(processStatus).catch(err => {
		console.log(`There has been a problem with your fetch operation: ${err.message}`);
	}).then(resposePackage);
};

export const authUploadFetch = async (url, formData, query) => {
	const options = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${auth.token()}`,
		},
		body: formData,
	};
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	return fetch(requestUri, options).then(processStatus).catch(err => {
		console.log(`There has been a problem with your fetch operation: ${err.message}`);
	}).then(resposePackage);
};

export const authDownload = async (url, method, postData, query) => {
	const options = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${auth.token()}`,
		},
		credentials: 'same-origin',
		body: JSON.stringify(postData),
	};
	const requestUri = query ? `${API_ENDPOINT}${url}?${query}` : `${API_ENDPOINT}${url}`;

	let filename = 'download';
	return fetch(requestUri, options)
		.then(res => {
			const disposition = res.headers.get('content-disposition');
			if (disposition && disposition.match(/attachment/)) {
				filename = disposition.replace(/attachment;.*filename=/, '').replace(/"/g, '');
			}
			return res.blob();
		})
		.then(blob => {
			const objurl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = objurl;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
};
