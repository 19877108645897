import { useState, useEffect, useRef } from 'react';

export const useBoolean = (options = {}) => {
	const { onTrue = () => {}, onFalse = () => {}, defaultBoolean = false } = options;

	const [boolean, setState] = useState(defaultBoolean);

	const toggle = () => {
		if (boolean) {
			onFalse();
		} else {
			onTrue();
		}
		setState(!boolean);
	};

	const setFalse = () => {
		setState(false);
		onFalse();
	};

	const setTrue = () => {
		setState(true);
		onTrue();
	};

	const focusInChildren = (relatedTarget, currentTarget) => {
		if (relatedTarget === null) {
			return false;
		}

		if (relatedTarget === currentTarget) {
			return true;
		}

		return focusInChildren(relatedTarget.parentNode, currentTarget);
	};

	return [boolean, { toggle, setFalse, setTrue, focusInChildren }];
};

export const useSelect = ({ defaultValue = [], onChange, propsValue }) => {
	const [value, setState] = useState(defaultValue);

	const onChangeValue = newValue => {
		setState([newValue]);
		onChange([newValue]);
	};

	useEffect(() => {
		if (propsValue) {
			setState(propsValue);
		}
	}, [propsValue]);

	return [value, onChangeValue];
};

export const useMultiSelect = ({ defaultValue = [], onChange, propsValue }) => {
	const [value, setState] = useState(defaultValue);

	const onSelect = newValue => {
		const hasValue = value.length > 0 && value.some(item => item.value === newValue.value);

		if (!hasValue) {
			setState(prev => {
				const newState = [...prev, newValue];
				onChange(newState);
				return newState;
			});
		}
	};

	const onDelect = newValue => {
		setState(prev => {
			const newState = prev.filter(item => item.value !== newValue.value);
			onChange(newState);
			return newState;
		});
	};

	useEffect(() => {
		if (propsValue) {
			setState(propsValue);
		}
	}, [propsValue]);

	return [value, { onSelect, onDelect }];
};

export const useListSelect = ({ defaultValue = [], onChange, propsValue }) => {
	const [value, setState] = useState(defaultValue);

	const onSelect = newValue => {
		const hasValue =
			value.length > 0 && value.some(item => JSON.stringify(item) === JSON.stringify(newValue));

		if (!hasValue) {
			setState(prev => {
				const newState = [...prev, newValue];
				onChange(newState);
				return newState;
			});
		}
	};

	const onDelect = newValue => {
		setState(prev => {
			const newState = prev.filter(item => JSON.stringify(item) !== JSON.stringify(newValue));
			onChange(newState);
			return newState;
		});
	};

	useEffect(() => {
		if (propsValue) {
			setState(propsValue);
		}
	}, [propsValue]);

	return [value, { onSelect, onDelect }];
};

export const useTimer = startTime => {
	const timer = useRef(null);
	const [time, setState] = useState(startTime);

	const reduceTime = () => {
		setState(prevTime => {
			const newTime = prevTime - 1;

			if (newTime === 0 && timer.current) {
				clearInterval(timer.current);
			}

			return newTime;
		});
	};

	useEffect(() => {
		if (time > 0) {
			timer.current = setInterval(reduceTime, 1000);
		}

		return () => {
			if (timer.current) {
				clearInterval(timer.current);
			}
		};
	}, [startTime]);

	return time;
};

export const useInterval = (execute, time) => {
	const timer = useRef(null);

	useEffect(() => {
		if (timer.current === null && time > 0) {
			timer.current = setInterval(execute, time);
		}

		return () => {
			if (timer.current) {
				clearInterval(timer.current);
				timer.current = null;
			}
		};
	}, [time]);
};

export const useTimeout = (execute, time) => {
	const timer = useRef(null);

	useEffect(() => {
		if (timer.current === null && time > 0) {
			timer.current = setTimeout(execute, time);
		}

		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
				timer.current = null;
			}
		};
	}, [time]);
};
