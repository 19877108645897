import React, { Component } from 'react';
import classnames from 'classnames';

const StaticIcon = ({ icon, error }) => {
	if (icon) {
		return (
			<a className={classnames('ui static-i', { error })}>
				<i className={`${icon} icon`} />
			</a>
		);
	}
	return null;
};

const TriangleIcon = ({ dropdown, onClick }) => {
	if (dropdown) {
		return (
			<a className={classnames('ui trangle-i')} onClick={onClick}>
				<i className={'angle up icon'} />
			</a>
		);
	}
	return (
		<a className={classnames('ui trangle-i')} onClick={onClick}>
			<i className={'angle down icon'} />
		</a>
	);
};

class Dropdown extends Component {
	constructor(props) {
		super(props);
		this._handleOutsideClick = this._handleOutsideClick.bind(this);
		this.state = {
			value: '',
			dropdown: false,
		};
	}

	_handleOutsideClick(e) {
		// ignore clicks on the component itself
		if (this.refDrop && !this.refDrop.contains(e.target)) {
			this._onDropdown();
		}
	}

	_onChange(value) {
		const { coded } = this.props;
		const showValue = coded ? value.value : value;
		const storeValue = coded ? value.id : value;

		if (showValue !== '') {
			this.setState(() => ({ value: showValue }));
		} else {
			this.setState(() => ({ value: '' }));
		}
		this._onDropdown();
		// Transmit to parent
		if (this.props.onChange) {
			this.props.onChange(storeValue);
		}
	}

	_onDropdown() {
		if (!this.state.dropdown) {
			// attach/remove event handler
			document.addEventListener('click', this._handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this._handleOutsideClick, false);
		}

		this.setState({ dropdown: !this.state.dropdown });
	}

	render() {
		const {
			className,
			error = false,
			disable = false,
			icon,
			label,
			options,
			coded,
			value,
		} = this.props;

		const optionItems = options && this.state.dropdown ? options.map(option => (
			<a
				key={coded ? option.id : option}
				className="item"
				onClick={() => this._onChange(option)}
			>
				{coded ? option.value : option}
			</a>
		)) : null;

		return (
			<div
				className={classnames('select', className, {
					valid: value && !error,
					dropdown: this.state.dropdown,
					error,
					icon,
				})}
				ref={n => { this.refDrop = n; }}
			>
				<StaticIcon icon={icon} error={error} />
				<div className={classnames('header', { icon })} onClick={() => disable || this._onDropdown()}>
					{value || label}
				</div>
				<TriangleIcon
					className="triangle"
					dropdown={this.state.dropdown}
					onClick={() => this._onDropdown()}
				/>
				<div
					className="menu"
					style={{ height: this.state.dropdown ? 'auto' : '0' }}
				>
					{optionItems}
				</div>
			</div>
		);
	}
}

export default Dropdown;
