import { SET_TARGET, SET_LIST } from 'react/constants/category';
import routePath from 'react/constants/path';
import categoryReq from 'react/request/category';
import { examCategory } from 'react/utils/exam';

import { loadingRequest, forwardTo, openWarningAlert } from './utils';

export function setCategoryList(categories) {
	return { type: SET_LIST, categories };
}

export function setTargetCategory(category) {
	return { type: SET_TARGET, category };
}


export function initialTarget() {
	return dispatch => {
		const initial = {
			name: '',
			size: [],
			brand: [],
			modelgrp: [],
			film: [],
			accessory: [],
			condition: [],
			unitedPrice: 0,
			online: false,
			sort: 0,
		};
		dispatch(setTargetCategory(initial));
	};
}

export function fetchCategoryList() {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { list } = await categoryReq.fetchList();
		dispatch(setCategoryList(list));
		dispatch(loadingRequest(false));
	};
}

export function fetchTargetCategory(id) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { content } = await categoryReq.fetchTarget(id);
		dispatch(setTargetCategory(content));
		dispatch(loadingRequest(false));
	};
}

export function createCategory(category) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const failList = examCategory(category);
		if (failList.length > 0) {
			dispatch(loadingRequest(false));
			return dispatch(openWarningAlert(failList.join('\n')));
		}

		const { success } = await categoryReq.create(category);
		if (success) {
			dispatch(forwardTo(routePath.categoryRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function duplicateCategory(id) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { content: category } = await categoryReq.fetchTarget(id);
		// clear duplicate id
		if (category) {
			delete category._id;

			// TODO: delete right obj
			category.modelgrp.forEach(m => {
				const mgrp = m;
				delete mgrp._id;
			});
			category.condition.forEach(c => {
				const cond = c;
				delete cond._id;
			});

			const { success } = await categoryReq.create(category);
			if (success) {
				dispatch(fetchCategoryList());
			}
		}
		dispatch(loadingRequest(false));
	};
}

export function updateCategory(category) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const failList = examCategory(category);
		if (failList.length > 0) {
			dispatch(loadingRequest(false));
			return dispatch(openWarningAlert(failList.join('\n')));
		}

		const updateData = {
			name: category.name,
			size: category.size,
			brand: category.brand,
			modelgrp: category.modelgrp,
			film: category.film,
			accessory: category.accessory,
			condition: category.condition,
			unitedPrice: category.unitedPrice,
			sort: category.sort,
		};
		const { success } = await categoryReq.update(category._id, updateData);
		if (success) {
			dispatch(forwardTo(routePath.categoryRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function changeCategoryStatus(ids, status) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const postData = { online: status };
		const successes = await Promise.all(ids.map(id => categoryReq.update(id, postData)));
		if (successes.some(s => s.success)) {
			dispatch(fetchCategoryList());
		}
		dispatch(loadingRequest(false));
	};
}

export function removeCategory(id) {
	return async dispatch => {
		const { success } = await categoryReq.delete(id);
		if (success) {
			dispatch(fetchCategoryList());
		}
	};
}
