import objectAssign from 'object.assign';
import _ from 'lodash';

import {
	OPEN_GLOBAL_MODAL,
	CLOSE_GLOBAL_MODAL,
	CLEAR_GLOBAL_MODAL,
} from '../constants/modal';

const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	removeFilmModal: false,
	removeAdminModal: false,
};

// Takes care of changing the application state
export function modal(state = initialState, action) {
	switch (action.type) {
		case OPEN_GLOBAL_MODAL:
			return assign({}, state, {
				[action.modal]: true,
			});
		case CLOSE_GLOBAL_MODAL:
			return assign({}, state, {
				[action.modal]: false,
			});
		case CLEAR_GLOBAL_MODAL: {
			const newState = _.mapValues(state, () => false);
			return assign({}, state, newState);
		}
		default:
			return state;
	}
}
