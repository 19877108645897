import { SET_TARGET_ID, SET_TARGET, SET_LIST } from 'react/constants/store';
import routePath from 'react/constants/path';
import storeReq from 'react/request/store';
import { examStore } from 'react/utils/exam';

import { loadingRequest, forwardTo, openWarningAlert } from './utils';

export function setStoreList(stores) {
	return { type: SET_LIST, stores };
}

export function setTargetStore(store) {
	return { type: SET_TARGET, store };
}

export function setTargetId(id) {
	return { type: SET_TARGET_ID, id };
}

export function initialTarget() {
	return dispatch => {
		const initial = {
			name: '',
			code: '',
			address: '',
			phone: '',
			storeClass: '',
			defaultDelivery: '',
			payment: '',
			priceCondition: '',
			invoicing: false,
			invoiceInfo: {
				title: '',
				number: '',
			},
			sales: null,
			account: null,
		};
		dispatch(setTargetStore(initial));
	};
}

export function fetchStoreList() {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { list } = await storeReq.fetchList();
		dispatch(setStoreList(list));
		dispatch(loadingRequest(false));
	};
}

export function fetchTargetStore(id) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { content } = await storeReq.fetchTarget(id);
		dispatch(setTargetStore(content));
		dispatch(loadingRequest(false));
	};
}

export function createStore(store) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const failList = examStore(store);
		if (failList.length > 0) {
			dispatch(loadingRequest(false));
			return dispatch(openWarningAlert(failList.join('\n')));
		}

		const { success } = await storeReq.create(store);
		if (success) {
			dispatch(forwardTo(routePath.storeRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function duplicateStore(id) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const { content: store } = await storeReq.fetchTarget(id);
		// clear duplicate id
		delete store._id;

		const { success } = await storeReq.create(store);
		if (success) {
			dispatch(forwardTo(routePath.storeRoot));
		}
		dispatch(loadingRequest(false));
	};
}

export function updateStore() {
	return async (dispatch, getState) => {
		dispatch(loadingRequest(true));
		const { store: { target: targetStore } } = getState();

		const failList = examStore(targetStore);
		if (failList.length > 0) {
			dispatch(loadingRequest(false));
			return dispatch(openWarningAlert(failList.join('\n')));
		}
		const updateData = {
			account: targetStore.account,
			name: targetStore.name,
			code: targetStore.code,
			address: targetStore.address,
			phone: targetStore.phone,
			storeClass: targetStore.storeClass,
			defaultDelivery: targetStore.defaultDelivery,
			payment: targetStore.payment,
			priceCondition: targetStore.priceCondition,
			invoicing: targetStore.invoicing,
			invoiceInfo: targetStore.invoiceInfo,
			sales: targetStore.sales,
		};
		const { success } = await storeReq.update(targetStore._id, updateData);
		if (success) {
			dispatch(forwardTo(routePath.storeRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function updateStoreAccount(newState) {
	return async dispatch => {
		dispatch(loadingRequest(true));

		const updateData = {
			account: newState.account,
		};
		const { success } = await storeReq.update(newState._id, updateData);
		if (success) {
			dispatch(forwardTo(routePath.storeRoot));
		}
		return dispatch(loadingRequest(false));
	};
}

export function removeStore(id) {
	return async dispatch => {
		const { success } = await storeReq.delete(id);
		if (success) {
			dispatch(fetchStoreList());
		}
	};
}
