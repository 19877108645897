import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';

import { useBoolean } from 'react/utils/hook';

import styles from './styles.css';

export const statusTranslate = code => {
	switch (code) {
		case 'NEW':
			return '新訂單';
		case 'PROCESSING':
			return '生產製作中';
		case 'SUCCESS':
			return '已完成';
		default:
			return '未完成';
	}
};

const options = ['NEW', 'PROCESSING', 'DELIVERY', 'SUCCESS'];

const StatusOptions = ({ className, children, disabled, onSelect }) => {
	const refOption = useRef(null);
	const [show, { toggle, setFalse, focusInChildren }] = useBoolean();

	useEffect(() => {
		if (refOption.current && show) {
			// Focus on panel after dropdown open
			refOption.current.focus();
		}
	});

	const onBlur = ({ relatedTarget }) => {
		if (!focusInChildren(relatedTarget, refOption.current)) {
			setFalse();
		}
	};

	return (
		<div
			ref={refOption}
			className={classnames(styles.statusOpt, className)}
			onBlur={onBlur}
			onClick={() => {
				if (!disabled) {
					toggle();
				}
			}}
			tabIndex="-1"
		>
			<p>{children}</p>
			<div
				className={classnames('menu', styles.opts, { [styles.show]: show })}
				style={{ height: show ? `${30 * options.length + 2}px` : '0' }}
			>
				{
					show && options.map(option => (
						<a
							key={option}
							className={styles.item}
							onClick={() => onSelect(option)}
						>
							{statusTranslate(option)}
						</a>
					))
				}
			</div>
		</div>
	);
};

export default StatusOptions;
