import jwtDecode from 'jwt-decode';
import routePath from 'react/constants/path';
import history from 'react/utils/history';


/**
 * Authentication lib
 * @type {Object}
 */
const windowsLocalStorage = window.localStorage;

const auth = {

	/**
	* Checks if anybody is logged in
	* @return {boolean} True if there is a logged in user, false if there isn't
	*/
	loggedIn() {
		return !!windowsLocalStorage.admintoken;
	},
	/**
	* Get the stored admintoken
	* @return {String} token string
	*/
	token() {
		return windowsLocalStorage.admintoken;
	},
	/**
	* Get the user's email
	* @return {String} email string
	*/
	email() {
		const token = windowsLocalStorage.admintoken;
		if (token) {
			const payload = jwtDecode(token);
			return payload.email;
		}
		return '';
	},
	/**
	* Get the user's id
	* @return {String} id string
	*/
	id() {
		return windowsLocalStorage.id;
	},
	/**
	* Get the user's authority
	* @return {String} authority number
	*/
	auth() {
		return windowsLocalStorage.auth;
	},

	iam(iam) {
		if (iam && iam === 'yuangogo') {
			windowsLocalStorage.iam = true;
		}
		return windowsLocalStorage.iam;
	},

	name() {
		const token = windowsLocalStorage.admintoken;
		if (token) {
			const payload = jwtDecode(token);
			return payload.name;
		}
		return '';
	},

	errorProcessing(status) {
		if (status === 401 || status === '401') {
			windowsLocalStorage.removeItem('admintoken');
			windowsLocalStorage.removeItem('auth');
			windowsLocalStorage.removeItem('iam');
			windowsLocalStorage.removeItem('id');
			window.location.reload();
			return history.push(routePath.login);
		}
		return 0;
	},

	onChange() {},
};

export default auth;
