import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from 'react/actions/admin';
import routePath from 'react/constants/path';
import logo from 'images/icon/profile.png';
import styles from './styles.css';

const AUTH_MAP = ['管理員帳號', '小編帳號', '生產部帳號', '業務帳號', '店家帳號'];

class UserMenu extends Component {
	constructor(props) {
		super(props);
		this._onToggleDropdown = this._onToggleDropdown.bind(this);

		this.state = {
			dropdown: false,
		};
	}

	_onToggleDropdown() {
		if (this.state.dropdown) {
			this.setState({ dropdown: false });
		} else {
			this.setState({ dropdown: true });
		}
	}

	render() {
		const { name, auth } = this.props;

		return (
			<div
				className={classnames(styles.menu, {
					[styles.dropdown]: this.state.dropdown,
				})}
				onClick={this._onToggleDropdown}
			>
				<img className={styles.logo} alt="logo" src={logo} />
				<div className={styles.info}>
					<p>{name || 'Moxbii User'}</p>
					<span>{AUTH_MAP[auth]}</span>
				</div>
				<i className="ui angle down icon" />
				<div className={styles.popover}>
					<Link to={routePath.root}>用戶設定</Link>
					<Link to={routePath.password}>變更密碼</Link>
					<Link to={routePath.permission}>權限一覽表</Link>
					<div className={styles.divider} />
					<Link to="" onClick={this.props.logout}><i className="ui sign out alternate icon" />登出</Link>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ admin: { name, auth } }) {
	return {
		name,
		auth,
	};
}

function mapDispatchToProps(dispatch) {
	return ({
		logout: () => dispatch(logout()),
	});
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
