import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

const ToolBar = ({ children, className }) => (
	<div className={classnames(className, styles.toolbar)}>
		{children}
	</div>
);

// Wrap the component to inject dispatch and state into it
export default ToolBar;
