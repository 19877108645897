import auth from 'react/utils/auth';
import { authEasyFetch, authBodyFetch, authDownload } from './endpoint';
import BaseRequest from './base';

class OrderRequest extends BaseRequest {
	constructor() {
		super('order');

		this.fetchList = async () =>
			authEasyFetch('/api/order/find', 'GET')
				.then(({ orders }) => Promise.resolve({ success: true, orders }))
				.catch(err => {
					if (err.status !== 200) {
						return auth.errorProcessing(err.status);
					}
					return Promise.resolve({ success: false });
				});

		this.fetchTarget = async id =>
			authEasyFetch(`/api/order/${id}`, 'GET')
				.then(({ order }) => Promise.resolve({ success: true, order }))
				.catch(err => {
					if (err.status !== 200) {
						return auth.errorProcessing(err.status);
					}
					return Promise.resolve({ success: false });
				});

		this.queryTarget = async q =>
			authEasyFetch('/api/order/find', 'GET', `category=${q.category}&start=${q.start}&end=${q.end}&sales=${q.sales}`)
				.then(({ orders }) => Promise.resolve({ success: true, orders }))
				.catch(err => {
					if (err.status !== 200) {
						return auth.errorProcessing(err.status);
					}
					return Promise.resolve({ success: false });
				});

		this.goship = async (id, postData) =>
			authBodyFetch(`/api/${this._root}/goship/${id}`, 'PUT', postData)
				.then(({ order }) => Promise.resolve({ success: true, order }))
				.catch(err => {
					if (err.status !== 200) {
						return auth.errorProcessing(err.status);
					}
					return Promise.resolve({ success: false });
				});

		this.exportStickers = async ids =>
			authDownload(`/api/${this._root}/export`, 'POST', { ids });

		this.exportOrder = async ids =>
			authDownload(`/api/${this._root}/exportForm`, 'POST', { ids });
	}
}

export default new OrderRequest();
