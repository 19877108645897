import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const StoreClassPage = Loadable({
	loader() {
		return import('react/components/Pages/StoreClass').then(object => object.default);
	},
	loading: LoadingDimmer,
});
