import React, { Component } from 'react';
import classnames from 'classnames';

import CircleButton from '../Button/CircleButton.react';
import LoadingIndicator from '../Button/LoadingIndicator.react';

const EditIcon = ({ editing, loading, removable, onEditToogle, onSubmitEdit, onDelete }) => {
	if (loading) {
		return (
			<CircleButton className="submit-i">
				<LoadingIndicator />
			</CircleButton>
		);
	}
	if (!editing) {
		return (
			<div className="float-icons">
				{
					removable &&
					<a className="ui edit-i" onClick={onDelete}>
						<i className="remove icon" />
					</a>
				}
				<a className="ui edit-i" onClick={onEditToogle}>
					<i className="edit icon" />
				</a>
			</div>
		);
	}
	return (
		<CircleButton className="submit-i" onClick={onSubmitEdit}>
			<i className="ui checkmark icon" />
		</CircleButton>
	);
};

class EditInput extends Component {
	constructor(props) {
		super(props);
		this._handleOutsideClick = this._handleOutsideClick.bind(this);
		this.state = {
			value: this.props.defaultValue,
			focus: false,
			editing: false,
		};
	}

	componentDidMount() {
		if (this.props.focus) {
			this.refInput.focus();
		}
	}

	componentWillReceiveProps(nextProps) {
		const nextValue = nextProps.defaultValue;
		const thisValue = this.props.defaultValue;

		if (!this.state.editing && nextValue !== thisValue) {
			this.setState({ value: nextValue });
		}
	}

	componentDidUpdate() {
		if (this.props.focus) {
			this.refInput.focus();
		}
	}

	_onFocus() {
		this.setState({ focus: true });
		if (this.props.onFocus) {
			this.props.onFocus({ focus: true });
		}
	}

	_onChange(event) {
		const nextValue = event.target.value;
		if (nextValue !== '') {
			this.setState(() => ({ value: nextValue }));
		} else {
			this.setState(() => ({ value: '' }));
		}
		// Transmit to parent
		if (this.props.onChange) {
			this.props.onChange(event);
		}
	}

	_changeEditState(editing) {
		this.setState({ editing });
		if (editing === true) {
			// attach/remove event handler
			document.addEventListener('click', this._handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this._handleOutsideClick, false);
		}
	}

	_handleOutsideClick(e) {
		// ignore clicks on the component itself
		if (!this.ref.contains(e.target)) {
			this._changeEditState(false);
			this.setState({ value: this.props.defaultValue });
		}
	}

	_submitEdit() {
		this._changeEditState(false);
		if (this.props.onSubmitEdit) {
			this.props.onSubmitEdit(this.state.value);
		}
		this.setState({ value: this.props.defaultValue });
	}

	render() {
		const {
			className,
			error = false,
			placeholder,
			htmlFor,
			name,
			type,
			maxLength,
			disabled,
			loading,
			removable,
			onDelete = () => { },
			onClick = () => {},
		} = this.props;

		return (
			<div
				className={classnames('framed-input editable', className, {
					valid: this.state.value && !error,
					editing: this.state.editing,
					focus: this.state.focus && !error,
					error,
				})}
				ref={n => { this.ref = n; }}
			>
				<input
					className={classnames({
						focus: this.state.focus && !error,
						disabled: disabled || !this.state.editing,
						error,
					})}
					ref={input => { this.refInput = input; }}
					placeholder={placeholder}
					id={htmlFor}
					name={name}
					type={type}
					value={this.state.value}
					maxLength={maxLength}
					onChange={evt => this._onChange(evt)}
					onFocus={() => this._onFocus()}
					onClick={onClick}
					disabled={disabled || !this.state.editing}
				/>
				<EditIcon
					editing={this.state.editing}
					loading={loading}
					removable={removable}
					onDelete={onDelete}
					onEditToogle={() => this._changeEditState(true)}
					onSubmitEdit={() => this._submitEdit()}
				/>
			</div>
		);
	}
}

export default EditInput;
