/**
 *
 * SideSubMenu.react.react.js
 *
 * This component renders the menu item of left hand side slide navigation bar
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';

class SideSubMenu extends Component {
	constructor(props) {
		super(props);

		const { location: { pathname }, links } = props;
		const matchLink = links === pathname;
		this.state = {
			menuSelected: '',
			matchLink,
		};
	}

	_menuItmeSelected(menuSelected) {
		if (this.state.menuSelected) {
			this.setState({ menuSelected: '' });
		} else {
			this.setState({ menuSelected });
		}
	}

	render() {
		const { title, links, icon, selected, onClick } = this.props;
		const { matchLink } = this.state;

		const menuSelected = selected ? selected === title : matchLink;

		return (
			<Link to={links} className="menu" onClick={() => onClick(title)}>
				<div className={classnames('icon-button', { active: menuSelected })}>
					<i className={icon} />
					<p>{title}</p>
				</div>
			</Link>
		);
	}
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect()(SideSubMenu));
