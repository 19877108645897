
const failRequired = (arr, obj) => Object.keys(arr).filter(a => !obj[a]);
const failNotEmpty = (arr, obj) => Object.keys(arr).filter(a => obj[a].length <= 0);

export const examCategory = category => {
	const requiredString = {
		name: '商品名稱為必填',
	};
	const notEmptyArry = {
		brand: '品牌選項不可為空',
		modelgrp: '型號選項不可為空',
		condition: '成本設置不可為空',
	};

	return [
		...failRequired(requiredString, category).map(k => requiredString[k]),
		...failNotEmpty(notEmptyArry, category).map(k => notEmptyArry[k]),
	];
};

export const examOrder = order => {
	const requiredString = {
		store: '店家資訊為必填',
	};
	const notEmptyArry = {
		items: '訂購商品不可為空',
	};

	// exam items vlaid
	const itemRequiredString = {
		category: '每件商品類別皆為必填',
		brand: '每件商品品牌皆為必填',
		model: '每件商品型號皆為必填',
		quantity: '每件商品數量皆為必填',
	};

	const itemFail = Object.keys(itemRequiredString).filter(a => !!order.items.find(it => !it[a]));

	return [
		...failRequired(requiredString, order).map(k => requiredString[k]),
		...failNotEmpty(notEmptyArry, order).map(k => notEmptyArry[k]),
		...itemFail.map(k => itemRequiredString[k]),
	];
};

export const examStore = store => {
	const requiredString = {
		name: '店家名稱為必填',
		storeClass: '店家分類為必填',
	};

	const coderegex = RegExp('[0-9]{3}');
	const matchcode = !coderegex.test(store.code) ? ['店家編碼格式不符'] : [];

	return [
		...failRequired(requiredString, store).map(k => requiredString[k]),
		...matchcode,
	];
};
