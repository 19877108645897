import { OPEN_GLOBAL_MODAL, CLOSE_GLOBAL_MODAL, CLEAR_GLOBAL_MODAL } from 'react/constants/modal';

export function openGlobalModal(modal) {
	return { type: OPEN_GLOBAL_MODAL, modal };
}
export function closeGlobalModal(modal) {
	return { type: CLOSE_GLOBAL_MODAL, modal };
}
export function clearGlobalModal(modal) {
	return { type: CLEAR_GLOBAL_MODAL, modal };
}
