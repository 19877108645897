export const HOST_MAP = {
	dev: 'https://moxbii.cacdidemo.com',
	demo: 'https://moxbii.cacdidemo.com',
	production: 'https://buyithere.tw',
};
/*
export const GCP_URL_MAP = {
	dev: 'http://gcs-sit.isurprice.com',
	production: 'https://gcs.isurprice.com',
};
*/

// const GCP_MAP_STATE = process.env.PROXY ? process.env.PROXY : process.env.API;

export const API_ENDPOINT = HOST_MAP[process.env.API];
export const DOMAIN = HOST_MAP[process.env.PROXY];
// export const GCP_API_ENDPOINT = GCP_URL_MAP[process.env.API];
// export const GCP_URL = GCP_URL_MAP[GCP_MAP_STATE];
