import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

const Dividend = ({ className }) => (
	<div className={classnames(className, styles.dividend)} />
);

export default Dividend;
