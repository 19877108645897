import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RowDefinition, ColumnDefinition } from 'griddle-react';
import objectAssign from 'object.assign';

import admauth from 'react/utils/auth';
import { useBoolean } from 'react/utils/hook';

import { PAYMENT_MAP, DELIVERY_MAP } from 'react/constants/order';
import { downloadCSV, downloadExcel }  from 'react/utils/download';
import Toggle from 'react/components/Utils/Input/Toggle';
import Switch from 'react/components/Utils/Input/Switch';
import IsurpriceGriddle from 'react/components/Utils/Griddle/IsurpriceGriddle';
import PopupModal from 'react/components/Utils/Modal/PopupModal';
import EditInput from 'react/components/Utils/Input/EditInput.react';
import routePath from 'react/constants/path';

import OrderFilterBar from './OrderFilterBar';
import OrderToolBar from './OrderToolBar';
import OrderViewer from './OrderViewer';
import StatusOptions, { statusTranslate } from './StatusOptions';

import styles from './styles.css';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

const sortByIsPaid = (data, column, sortAscending = true) => data.sort((original, newRecord) => {
	const columnKey = column.split('.');
	const originalValue = (original.hasIn(columnKey) && original.getIn(columnKey)) || '';
	const newRecordValue = (newRecord.hasIn(columnKey) && newRecord.getIn(columnKey)) || '';

	const odata = JSON.parse(originalValue);
	const ndata = JSON.parse(newRecordValue);
	if (odata.paid === ndata.paid) {
		return 0;
	} else if (odata.paid > ndata.paid) {
		return sortAscending ? 1 : -1;
	}

	return sortAscending ? -1 : 1;
});

const SelectComponent = ({ value, onSelected }) => {
	const data = JSON.parse(value);

	return (
		<Toggle
			htmlFor={data.id}
			defaultValue={data.check}
			onClose={() => onSelected(data.id, false, data.selected)}
			onOpen={() => onSelected(data.id, true, data.selected)}
			close="&#160;&#160;"
			open="&#10004;"
			small
			button
		/>
	);
};

const StatusComponent = ({ value, changeStatus, auth }) => {
	const data = JSON.parse(value);
	if (auth < 3) {
		return (
			<StatusOptions className={styles.status} onSelect={status => changeStatus(data.id, status)}>
				{data.status}
			</StatusOptions>
		);
	}
	return <div className={styles.status}>{data.status}</div>;
};

const ToggleComponent = ({ value, setPaid }) => {
	const data = JSON.parse(value);
	const _onSubmit = () => ({
		_id: data.orderId,
		payment: {
			method: data.method,
			_id: data._id,
			paid: !data.paid,
		},
	});
	return (
		<Switch active={data.paid} onClick={() => setPaid(_onSubmit())} />
	);
};

const ActionComponent = ({ value, auth, clickView, clickDownload, clickRemove, goto }) => {
	const data = JSON.parse(value);
	return (
		<div className={styles.dataAction}>
			{data.total}
			<div className="actions">
				<button className={styles.actbtn} onClick={() => clickView(data.id)}>
					<i className="eye icon" />
				</button>
				{ (data.status === 'NEW' || auth < 2) &&
					<button className={styles.actbtn} onClick={() => goto(`${routePath.orderEdit}/${data.id}`)}>
						<i className="pencil icon" />
					</button>
				}
				<button className={styles.actbtn} onClick={() => clickDownload(data.id)}>
					<i className="download icon" />
				</button>
				<button className={styles.actbtn} onClick={() => clickRemove(data.id)}>
					<i className="trash alternate outline icon" />
				</button>
			</div>
		</div>
	);
};

const EditInputComponent = ({ value, update, type, columnId, _onChange, auth, clickView, clickDownload }) => {
	const data = JSON.parse(value);
	const dataType = data[type];

	const _submit = () => {
		if (columnId === 'listnote') {
			update({
				_id: data.orderId,
				listnote: data.listnote,
			});
		} else {
			update({
				_id: data.orderId,
				shipment: {
					...data,
					name: data.shipment,
				},
			});
		}
	};

	if (auth < 2) {
		if (columnId === 'shipmentSerial') {
			return (
				<EditInput
					type="text"
					onSubmitEdit={_submit}
					defaultValue={dataType}
					onChange={evt => _onChange(evt, data, columnId)}
				/>
			);
		} else if (columnId === 'shippingAt') {
			return (
				<EditInput
					className={styles.editInput}
					type="date"
					onSubmitEdit={_submit}
					defaultValue={dataType}
					onChange={evt => _onChange(evt, data, columnId)}
				/>
			);
		}
		return (
			<EditInput
				className={styles.editInput}
				type="text"
				onSubmitEdit={_submit}
				defaultValue={dataType}
				onChange={evt => _onChange(evt, data, columnId)}
			/>
		);
	}

	if (columnId === 'listnote' && (auth === '2' || auth === '4')) {
		return (
			<div className={styles.dataAction} style={{ marginTop: -8 }}>
				{dataType}
				<div className="actions">
					<button className={styles.actbtn} onClick={() => clickView(data.orderId)}>
						<i className="eye icon" />
					</button>
					<button className={styles.actbtn} onClick={() => clickDownload(data.orderId)}>
						<i className="download icon" />
					</button>
				</div>
			</div>
		);
	}

	return (
		<p>{dataType}</p>
	);
};

const FilterBar = ({ children }) => (
	<div className={styles.filterbar}>
		<div className={styles.filter}>{children}</div>
	</div>
);

const OrderList = ({
	stores,
	categories,
	orders,
	salesList,
	size,
	setSize,
	status,
	setStatus,
	store,
	setStore,
	curPage,
	setCurPage,
	selected,
	selectedAll,
	onSelected,
	onSelectAll,
	callDownload,
	callRemove,
	callChangeStatus,
	callStickers,
	goto,
	queryTargetOrder,
	update,
	updateShip,
	setPaid,
	auth,
}) => {
	const [sales, setSales] = useState('全部業務');
	const [product, setProduct] = useState('全部商品');
	const [time, setTime] = useState({
		start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	});
	const [viewingId, setViewingId] = useState();
	const [viewModal, { setTrue: openViewer, setFalse: closeViewer }] = useBoolean();

	useEffect(() => {
		queryTargetOrder({
			category: product === '全部商品' ? '' : product,
			start: time.start,
			end: time.end,
			sales: sales === '全部業務' || !sales ? '' : sales._id,
		});
	}, [sales, product, time]);

	const _onChange = (evt, data, columnId) => {
		if (columnId === 'shipmentSerial') {
			return assign(data, {
				serial: evt.target.value,
			});
		}
		return assign(data, {
			[columnId]: evt.target.value,
		});
	};

	const orderItems = orders
	.filter(o => (
		(status === '全部狀態' || statusTranslate(o.status) === status) &&
		(store === '全部店家' || o.store.name === store)
	))
	.map((order, index) => ({
		check: JSON.stringify(
			{
				check: !!selected.find(id => id === order._id),
				selected,
				id: order._id,
			}),
		status: JSON.stringify(
			{
				id: order._id,
				status: statusTranslate(order.status),
			}),
		serial: order.serial,
		store: order.store.name,
		shipment: DELIVERY_MAP[order.shipment.name],
		shipmentSerial: JSON.stringify(
			{
				orderId: order._id,
				shipment: order.shipment.name,
				serial: order.shipment.serial || '',
				shippingAt: order.shipment.shippingAt ? moment(order.shipment.shippingAt).format('YYYY-MM-DD') : '',
				package: order.shipment.package,
				price: order.shipment.price,
				_id: order.shipment._id,
			}),
		shippingAt: JSON.stringify(
			{
				orderId: order._id,
				shipment: order.shipment.name,
				serial: order.shipment.serial || '',
				shippingAt: order.shipment.shippingAt ? moment(order.shipment.shippingAt).format('YYYY-MM-DD') : '',
				package: order.shipment.package,
				price: order.shipment.price,
				_id: order.shipment._id,
			}),
		listnote: JSON.stringify(
			{
				orderId: order._id,
				index,
				listnote: order.listnote || '',
			}),
		payment: PAYMENT_MAP[order.payment.method],
		isPaid: JSON.stringify(
			{
				orderId: order._id,
				method: order.payment.method,
				_id: order.payment._id,
				paid: order.payment.paid || false,
			}),
		amount: order.items.reduce((r, it) => r + it.quantity, 0),
		commission: order.commission,
		total: JSON.stringify(
			{
				id: order._id,
				index,
				total: (order.totalTax || order.total).toFixed(2),
				status: order.status,
			}),
		id: order._id,
	}));

	const rowDataArray = orders
		.filter(o => (
			(status === '全部狀態' || statusTranslate(o.status) === status) &&
			(store === '全部店家' || o.store.name === store)
		))
		.map(order => [
			statusTranslate(order.status),
			order.serial,
			order.batch,
			order.store.name,
			order.store.phone,
			order.store.address,
			DELIVERY_MAP[order.shipment.name],
			order.shipment.serial || '',
			order.shipment.shippingAt ? moment(order.shipment.shippingAt).format('YYYY-MM-DD') : '',
			PAYMENT_MAP[order.payment.method],
			(order.totalTax || order.total).toFixed(2).toString(),
			order.commission.toString(),
			order.items.map(it => `【${it.category}】${it.brand}${it.model}-${it.film} ${it.design} x ${it.quantity}`).join(','),
		]);
	const title = [
		'狀態',
		'單號',
		'批號',
		'店家',
		'店家電話',
		'店家地址',
		'運送方式',
		'物流單號',
		'出貨時間',
		'付款方式',
		'總價',
		'傭金',
		'商品',
	];

	return (
		<div>
			<OrderFilterBar
				store={store}
				storeList={stores}
				categoryList={categories}
				sales={sales === '全部業務' || !sales ? sales : sales.name}
				salesList={salesList}
				product={product}
				time={time}
				setStore={setStore}
				setSales={setSales}
				setProduct={setProduct}
				setTime={setTime}
				auth={auth}
			/>
			<OrderToolBar
				auth={auth}
				selected={selected.length > 0}
				size={size}
				status={status}
				setSize={setSize}
				setStatus={setStatus}
				deleteSelect={() => callRemove(selected)}
				changeSelect={st => callChangeStatus(selected, st)}
				exportStickers={() => callStickers(selected)}
				exportSelect={() => callDownload(selected)}
				selectedAll={selectedAll[curPage - 1]}
				onSelectAll={onSelectAll}
				onExport={type => {
					if (type === 'csv') {
						downloadCSV('order.csv', title, rowDataArray);
					} else {
						downloadExcel('order.xlsx', title, rowDataArray);
					}
				}}
			/>
			<IsurpriceGriddle
				ToolBar={FilterBar}
				data={orderItems}
				size={size}
				page={curPage}
				onChangePage={setCurPage}
			>
				<RowDefinition>
					<ColumnDefinition
						id="check"
						title="選擇"
						width={50}
						style={{ minWidth: 50 }}
						customComponent={SelectComponent}
						extraData={{ onSelected }}
					/>
					<ColumnDefinition
						id="status"
						title="狀態"
						width={100}
						style={{ minWidth: 100 }}
						customComponent={StatusComponent}
						extraData={{ changeStatus: (id, st) => callChangeStatus([id], st), auth }}
					/>
					<ColumnDefinition id="serial" title="訂單編號" />
					<ColumnDefinition
						id="store"
						title="店家名稱"
						width={150}
						style={{ maxWidth: 150, overflow: 'hidden',	whiteSpace: 'nowrap',	textOverflow: 'ellipsis' }}
					/>
					<ColumnDefinition id="shipment" title="物流方式" />
					<ColumnDefinition
						id="shipmentSerial"
						title="物流單號"
						width={190}
						style={{ minWidth: 190 }}
						customComponent={EditInputComponent}
						extraData={{ update, type: 'serial', _onChange, auth }}
					/>
					<ColumnDefinition
						id="shippingAt"
						title="出貨日期"
						width={170}
						style={{ minWidth: 150, maxWidth: 170 }}
						customComponent={EditInputComponent}
						extraData={{ update: updateShip, type: 'shippingAt', _onChange, auth }}
					/>
					{auth < 2 &&
						<ColumnDefinition id="payment" title="付款方式" />
					}
					{auth < 2 &&
						<ColumnDefinition
							id="isPaid"
							title="是否付款"
							sortMethod={sortByIsPaid}
							customComponent={ToggleComponent}
							extraData={{ setPaid }}
						/>
					}
					<ColumnDefinition
						id="listnote"
						title="列表備註"
						width={200}
						style={{ minWidth: 150 }}
						customComponent={EditInputComponent}
						extraData={{
							update,
							type: 'listnote',
							_onChange,
							auth,
							clickView: id => { setViewingId(id); openViewer(); },
							clickDownload: id => callDownload([id]),
						}}
					/>
					{auth < 4 && auth !== '2' &&
						<ColumnDefinition id="amount" width={80} title="數量" />
					}
					{(auth < 1 || (auth === '3' && admauth.iam())) &&
						<ColumnDefinition id="commission" width={80} title="元哥" />
					}
					{auth < 4 && auth !== '2' &&
						<ColumnDefinition
							id="total"
							title="金額"
							width={100}
							customComponent={ActionComponent}
							extraData={{
								auth,
								goto,
								clickView: id => { setViewingId(id); openViewer(); },
								clickDownload: id => callDownload([id]),
								clickRemove: id => callRemove([id]),
							}}
						/>
					}
				</RowDefinition>
			</IsurpriceGriddle>
			<PopupModal modalVisible={viewModal} close={closeViewer}>
				{orders.length > 0 && (
					<OrderViewer order={orders.find(o => o._id === viewingId)} close={closeViewer} />
				)}
			</PopupModal>
		</div>
	);
};

export default OrderList;
