const CMS_PATH_ROOT = {
	development: '',
	production: '',
};

export default {
	root: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/`,
	adminList: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/admin/list`,
	login: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/admin/login`,
	adminconfirm: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/admin/confirmlogin`,
	password: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/admin/password`,
	permission: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/permission`,
	orderRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/`,
	orderCreate: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/order/create`,
	orderEdit: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/order/edit`,
	categoryRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/category`,
	categoryCreate: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/category/create`,
	categoryEdit: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/category/edit`,
	filmRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/film`,
	storeRoot: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/store`,
	storeCreate: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/store/create`,
	storeEdit: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/store/edit`,
	storeClass: `${CMS_PATH_ROOT[process.env.NODE_ENV]}/store-class`,
};
