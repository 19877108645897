/*
*
* app.js
* This is the entry file for the application
*
*/
import 'babel-polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import history from 'react/utils/history';

// Import the CSS file, which webpack transfers to the build folder
import 'react-dates/lib/css/_datepicker.css';
import 'styles/bootstrap/bootstrap.css';
import 'styles/semantic/semantic.min.css';
import 'styles/quill/quill.snow.css';
import 'styles/main.css';

import configureStore from './store';
import Routes from './Routes.js';

const store = configureStore({});


// These are the pages you can go to,
// which are all wrapped in the AdminDashboard component, which contains the navigation etc

ReactDOM.render(
	<AppContainer>
		<Routes store={store} history={history} />
	</AppContainer>,
	document.getElementById('app'));

if (module.hot) {
	module.hot.accept('./Routes.js', () => {
		const NewRoutes = require('./Routes.js').default; // eslint-disable-line global-require
		ReactDOM.render(
			<AppContainer>
				<NewRoutes store={store} history={history} />
			</AppContainer>,
			document.getElementById('app'));
	});
}
