exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__button___2Q7Vf{background:#e4007f;padding:4px 10px;border-radius:5px;border:none;color:#fff;outline:none}.styles__button-reverse___xjxwZ{background:#fff;border:1px solid #e4007f;color:#e4007f}.styles__small___2DXSM{font-size:16px;height:1.5em;line-height:1.5em;padding:0 .38em}.styles__tab___iYE7Q{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:distribute;justify-content:space-around}.styles__tab___iYE7Q>.styles__close___2C_Pp{width:100%;border:1px solid #eee;background-color:#fff;color:#e4007f;padding:5px;transition:all .5s linear}.styles__tab___iYE7Q>.styles__open___lLO2H{width:100%;border:1px solid #eee;background-color:#d5d5d5;color:#777;padding:5px;transition:all .5s linear}.styles__tab___iYE7Q.styles__toggled___3WAim>.styles__close___2C_Pp{background-color:#d5d5d5;color:#777}.styles__tab___iYE7Q.styles__toggled___3WAim>.styles__open___lLO2H{background-color:#fff;color:#e4007f}.styles__tab___iYE7Q.styles__small___2DXSM{width:60px;height:30px}.styles__tab___iYE7Q.styles__small___2DXSM>.styles__close___2C_Pp,.styles__tab___iYE7Q.styles__small___2DXSM>.styles__open___lLO2H{padding:1px}.styles__toggle___ivJ0Q{cursor:pointer;margin:auto}.styles__toggle___ivJ0Q>input{display:none}", ""]);

// exports
exports.locals = {
	"button": "styles__button___2Q7Vf",
	"button-reverse": "styles__button-reverse___xjxwZ styles__button___2Q7Vf",
	"buttonReverse": "styles__button-reverse___xjxwZ styles__button___2Q7Vf",
	"small": "styles__small___2DXSM",
	"tab": "styles__tab___iYE7Q",
	"close": "styles__close___2C_Pp",
	"open": "styles__open___lLO2H",
	"toggled": "styles__toggled___3WAim",
	"toggle": "styles__toggle___ivJ0Q"
};