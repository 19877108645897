
import objectAssign from 'object.assign';

import { SET_TARGET_ID, SET_TARGET, SET_LIST } from 'react/constants/store';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	targetId: '',
	list: [],
	target: {
		_id: '',
		name: '',
		address: '',
		phone: '',
		storeClass: '',
		defaultDelivery: '',
		payment: '',
		priceCondition: '',
		invoicing: false,
		invoiceInfo: {
			title: '',
			number: '',
		},
		sales: null,
		account: null,
	},
};

// Takes care of changing the application state
export function store(state = initialState, action) {
	switch (action.type) {
		case SET_TARGET_ID:
			return assign({}, state, {
				targetId: action.id,
			});
		case SET_TARGET:
			return assign({}, state, {
				target: action.store,
			});
		case SET_LIST:
			return assign({}, state, {
				list: action.stores,
			});
		default:
			return state;
	}
}
