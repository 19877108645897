import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, withRouter } from 'react-router-dom';

import routePath from 'react/constants/path';

import App from './components';
import Dashboard from './components/Pages/Dashboard';

// Import the components used as pages
import { AdminListPage, LoginPage, ResetPage, ConfirmPage } from './routes/admin';
import { CategoryPage, CategoryCreate, CategoryEdit } from './routes/category';
import { OrderCreate, OrderEdit } from './routes/order';
import { FilmPage } from './routes/film';
import { StorePage, StoreCreate, StoreEdit } from './routes/store';
import { StoreClassPage } from './routes/storeClass';
import { PermissionPage } from './routes/permission';

const Routes = ({ store, history }) => (
	<Provider store={store}>
		<Router history={history}>
			<App>
				<Route exact path={routePath.root} component={Dashboard} />

				<Route exact path={routePath.login} component={LoginPage} />
				<Route exact path={routePath.password} component={ResetPage} />
				<Route exact path={routePath.adminconfirm} component={ConfirmPage} />
				<Route exact path={routePath.adminList} component={AdminListPage} />

				<Route exact path={routePath.filmRoot} component={FilmPage} />

				<Route exact path={routePath.storeClass} component={StoreClassPage} />

				<Route exact path={routePath.categoryRoot} component={withRouter(CategoryPage)} />
				<Route exact path={routePath.categoryCreate} component={CategoryCreate} />
				<Route path={`${routePath.categoryEdit}/:id`} component={CategoryEdit} />

				<Route exact path={routePath.storeRoot} component={withRouter(StorePage)} />
				<Route exact path={routePath.storeCreate} component={StoreCreate} />
				<Route path={`${routePath.storeEdit}/:id`} component={StoreEdit} />

				<Route exact path={routePath.orderCreate} component={OrderCreate} />
				<Route path={`${routePath.orderEdit}/:id`} component={OrderEdit} />

				<Route exact path={routePath.permission} component={PermissionPage} />
			</App>
		</Router>
	</Provider>
);

export default Routes;
