import auth from 'react/utils/auth';
import { authBodyFetch, authEasyFetch } from './endpoint';

class BaseRequest {
	constructor(apiRoot) {
		this._root = apiRoot;
	}

	async fetchList() {
		return authEasyFetch(`/api/${this._root}/find`, 'GET')
		.then(({ list }) => Promise.resolve({ success: true, list }))
		.catch(err => {
			if (err.status !== 200) {
				return auth.errorProcessing(err.status);
			}
			return Promise.resolve({ success: false });
		});
	}

	async fetchTarget(id) {
		return authEasyFetch(`/api/${this._root}/${id}`, 'GET')
		.then(({ content }) => Promise.resolve({ success: true, content }))
		.catch(err => {
			if (err.status !== 200) {
				return auth.errorProcessing(err.status);
			}
			return Promise.resolve({ success: false });
		});
	}

	async create(postData) {
		return authBodyFetch(`/api/${this._root}/create`, 'POST', postData)
			.then(({ content }) => Promise.resolve({ success: true, content }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	}

	async update(id, postData) {
		return authBodyFetch(`/api/${this._root}/${id}`, 'PUT', postData)
			.then(({ content }) => Promise.resolve({ success: true, content }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	}

	async delete(id) {
		return authEasyFetch(`/api/${this._root}/${id}`, 'DELETE')
			.then(() => Promise.resolve({ success: true }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	}
}

export default BaseRequest;
