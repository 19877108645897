import React, { Component } from 'react';
import Griddle, { plugins } from 'griddle-react';

import styles from './styles.css';

class IsurpriceGriddle extends Component {
	constructor(props) {
		super(props);

		// Initialize to the first page of results.
		this.state = {
			currentPage: props.page,
		};
	}

	_onNext() {
		const { currentPage } = this.state;
		this.setState({
			currentPage: currentPage + 1,
		});
		this.props.onChangePage(currentPage + 1);
	}

	_onPrevious() {
		const { currentPage } = this.state;
		this.setState({
			currentPage: currentPage - 1,
		});
		this.props.onChangePage(currentPage - 1);
	}


	render() {
		const { children, ToolBar, data, size, onChangePage, pagination = true } = this.props;
		const styleConfig = {
			classNames: {
				Table: styles.table,
				Pagination: styles.pagination,
				Filter: styles.filter,
			},
		};

		const NewLayout = ({ Table, Pagination, Filter }) => (
			<div className={styles.griddle}>
				{
					!ToolBar &&
					<div className={styles.toolbar}>
						<Filter />
					</div>
				}
				{
					ToolBar &&
					<ToolBar><Filter /></ToolBar>
				}
				<div className={styles.scroll}>
					<Table />
				</div>
				{pagination && <Pagination />}
			</div>
		);

		const pageProperties = {
			pageSize: size,
		};

		return (
			<div>
				<Griddle
					styleConfig={styleConfig}
					data={data}
					plugins={[plugins.LocalPlugin]}
					components={{
						Layout: NewLayout,
					}}
					pageProperties={pageProperties}
					textProperties={{
						filterPlaceholder: '輸入關鍵字開始搜尋...',
						next: '下一頁',
						previous: '上一頁',
					}}
					events={onChangePage ? {
						onNext: () => this._onNext(),
						onPrevious: () => this._onPrevious(),
						onGetPage: curPage => onChangePage(curPage),
					} : null}
				>
					{children}
				</Griddle>
			</div>
		);
	}
}

// Wrap the component to inject dispatch and state into it
export default IsurpriceGriddle;
