exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__alert___dfu0d{display:-ms-inline-flexbox;display:inline-flex;max-width:600px;min-width:300px;min-height:40px;border-radius:2px;box-shadow:0 2px 8px 0 rgba(65,65,65,.5);background-color:#e4007f;padding:10px;opacity:0}.styles__alert___dfu0d.styles__warning___30hNn{background-color:#ff4c4c}.styles__alert___dfu0d>.styles__content___2aU1X{display:-ms-flexbox;display:flex;-ms-flex-line-pack:center;align-content:center}.styles__alert___dfu0d>.styles__content___2aU1X>i{color:#fff}.styles__alert___dfu0d>.styles__content___2aU1X>span{margin-left:10px;font-size:14px;line-height:1.57;color:#fff;white-space:pre}.styles__modal___12Fk6{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100vw;height:auto;position:fixed;top:50px;left:0;overflow:auto;opacity:0;transition:all .75s cubic-bezier(.68,-.55,.265,1.55);z-index:-1}.styles__modal___12Fk6.styles__active___1n1lD{opacity:1;z-index:200}.styles__modal___12Fk6.styles__active___1n1lD>div{opacity:1}.styles__modal___12Fk6.styles__inline___1Z88h{position:absolute}", ""]);

// exports
exports.locals = {
	"alert": "styles__alert___dfu0d",
	"warning": "styles__warning___30hNn",
	"content": "styles__content___2aU1X",
	"modal": "styles__modal___12Fk6",
	"active": "styles__active___1n1lD",
	"inline": "styles__inline___1Z88h"
};