import React from 'react';
import classnames from 'classnames';

const Button = ({
	className,
    transparent,
	children,
	step,
	icon,
	small,
	filled,
    inverse,
    disabled,
    onClick = () => {},
    ...props
}) => (
	<button
		className={classnames(className, 'button', {
			step: step || icon,
			small,
			filled,
			transparent,
			inverse,
			disabled,
		})}
		onClick={e => {
			if (!disabled) {
				onClick(e);
			}
		}}
		{...props}
	>
		<div>
			{children}
			{step && <i className="ui chevron right icon" />}
			{icon && <i className={`ui ${icon} icon`} />}
		</div>
	</button>
);

export default Button;
