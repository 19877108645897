import React from 'react';
import classnames from 'classnames';

import styles from './styles.css';

const Switch = ({ active, onClick = () => {} }) => (
	<div
		className={classnames(styles.wrapper, active && styles.active)}
	>
		<button type="button" onClick={onClick} />
	</div>
);

export default Switch;

