import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './styles.css';

class Toggle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			toggle: this.props.defaultValue || false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const nextValue = nextProps.defaultValue;
		const thisValue = this.props.defaultValue;

		if (!this.state.editing && nextValue !== thisValue) {
			this.setState({ toggle: nextValue });
		}
	}

	_handleClick(evt) {
		evt.preventDefault();
		if (this.state.toggle) {
			this.props.onClose();
		} else {
			this.props.onOpen();
		}
		this.setState({ toggle: !this.state.toggle });
	}

	render() {
		const {
			className,
			htmlFor,
			small,
			button,
			close = 'Close',
			open = 'Open',
			disabled,
			onDisableClick,
		} = this.props;

		const toggle = this.state.toggle;
		const buttonChild = toggle ? open : close;
		const child = (
			<div className={classnames(styles.tab, {
				[styles.toggled]: toggle,
				[styles.small]: small,
			})}
			>
				<div className={styles.close}>{close}</div>
				<div className={styles.open}>{open}</div>
			</div>
		);

		return (
			<label
				htmlFor={htmlFor}
				className={classnames(className, styles.toggle, {
					[styles.button]: button && toggle,
					[styles.buttonReverse]: button && !toggle,
					[styles.small]: small,
				})}
			>
				<input
					id={htmlFor}
					name={htmlFor}
					type="checkbox"
					onClick={e => {
						if (!disabled) {
							this._handleClick(e);
						} else {
							onDisableClick();
						}
					}}
				/>
				{button ? buttonChild : child}
			</label>
		);
	}
}

export default Toggle;
