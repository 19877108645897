
import objectAssign from 'object.assign';

import { SET_TARGET, SET_LIST } from 'react/constants/category';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	list: [],
	target: {
		_id: '',
		name: '',
		size: [],
		brand: [],
		modelgrp: [],
		film: [],
		accessory: [],
		condition: [],
		unitedPrice: 0,
		online: false,
		createdAt: '',
		modifiedAt: '',
	},
};

// Takes care of changing the application state
export function category(state = initialState, action) {
	switch (action.type) {
		case SET_TARGET:
			return assign({}, state, {
				target: action.category,
			});
		case SET_LIST:
			return assign({}, state, {
				list: action.categories,
			});
		default:
			return state;
	}
}
