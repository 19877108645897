import React from 'react';
import classnames from 'classnames';

import Button from '../../Button/Button.react.js';

import styles from './styles.css';

const ConfirmDialog = ({ className, icon, header, description, deny, approve }) => (
	<div className={classnames(styles.dialog, className)}>
		<div className={styles.icon}>
			<i className={`ui ${icon} icon`} />
		</div>
		<div className={styles.title}>
			{header}
		</div>
		<div className={styles.body}>
			{description}
		</div>
		<div className={styles.action}>
			{deny ?
				<Button onClick={deny} className={styles.deny}>
					取消
				</Button>
				: null
			}
			<Button onClick={approve} inverse>
				確定
			</Button>
		</div>
	</div>
);

export default ConfirmDialog;
