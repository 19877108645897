exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__toolbar___34YRc{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:40px;padding:5px;border-radius:2.5px;background-color:#f5f5f5}.styles__toolbar___34YRc p{font-size:15px;line-height:30px;padding:0 3px}.styles__toolbar___34YRc span{min-width:.5em;font-size:15px}@media screen and (max-width:719px){.styles__toolbar___34YRc{height:auto}}", ""]);

// exports
exports.locals = {
	"toolbar": "styles__toolbar___34YRc"
};