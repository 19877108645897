/*
 * accountConstants
 * These are the variables that determine what our central data store (reducer.js)
 * changes in our state. When you add a new action, you have to add a new constant here
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */
export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_NAME = 'SET_AUTH_NAME';
export const SET_AUTHLEVEL = 'SET_AUTHLEVEL';
export const SET_ADMIN_LIST = 'SET_ADMIN_LIST';
export const SET_TARGET = 'SET_ADMIN_TARGET';
export const SET_LOGIN_CONFIRM = 'SET_LOGIN_CONFIRM';
