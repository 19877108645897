/**
 *
 * InformationCard.react.js
 *
 * This component renders alert card for one information
 *
 */

import React from 'react';

import styles from './styles.css';

const InformationCard = ({ icon, number, topic }) => (
	<div className={styles.infoCard}>
		<i className={`${icon} icon`} />
		<div className={styles.right}>
			<div className={styles.number}>{number}</div>
			<div className={styles.topic}>{topic}</div>
		</div>
	</div>
);

export default InformationCard;
