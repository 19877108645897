
import objectAssign from 'object.assign';

import { SET_TARGET_ID, SET_TARGET, SET_LIST } from 'react/constants/film';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	targetId: '',
	list: [],
	target: {
		_id: '',
		name: '',
		designs: [],
	},
};

// Takes care of changing the application state
export function film(state = initialState, action) {
	switch (action.type) {
		case SET_TARGET_ID:
			return assign({}, state, {
				targetId: action.id,
			});
		case SET_TARGET:
			return assign({}, state, {
				target: action.film,
			});
		case SET_LIST:
			return assign({}, state, {
				list: action.films,
			});
		default:
			return state;
	}
}
