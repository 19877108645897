
import objectAssign from 'object.assign';
import auth from '../utils/auth';

import {
	SET_AUTH_NAME,
	SET_AUTH,
	SET_AUTHLEVEL,
	SET_ADMIN_LIST,
	SET_TARGET,
	SET_LOGIN_CONFIRM,
} from '../constants/admin';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	name: auth.name(),
	loggedIn: auth.loggedIn(),
	auth: auth.auth(),
	list: [],
	confirm: '',
	target: {
		_id: '',
		name: '',
		auth: 0,
		code: '',
		store: '',
	},
};

// Takes care of changing the application state
export function admin(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH_NAME:
			return assign({}, state, {
				name: action.newState,
			});
		case SET_AUTH:
			return assign({}, state, {
				loggedIn: action.newState,
			});
		case SET_AUTHLEVEL:
			return assign({}, state, {
				auth: action.newState,
			});
		case SET_TARGET:
			return assign({}, state, {
				target: action.admin,
			});
		case SET_ADMIN_LIST:
			return assign({}, state, {
				list: action.newState,
			});
		case SET_LOGIN_CONFIRM:
			return assign({}, state, {
				confirm: action.newState,
			});
		default:
			return state;
	}
}
