import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const PermissionPage = Loadable({
	loader() {
		return import('react/components/Pages/Permission').then(object => object.default);
	},
	loading: LoadingDimmer,
});
