exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dialog___2qWmG{position:relative;width:auto;height:auto;max-width:300px;max-height:500px;left:0;right:0;margin:auto;border:1px solid #eee;border-radius:10px;box-shadow:0 0 5px 0 rgba(0,0,0,.07);background-color:#fff;padding:1em 2em;text-align:center}.styles__dialog___2qWmG>.styles__icon___3Dpcp{font-size:40px;color:#fff;background-color:#e4007f;border-radius:50%;width:80px;height:80px;left:0;right:0;margin:auto;box-shadow:3px 3px 5px 0 rgba(0,0,0,.3);margin-top:-50px;padding-top:12%}.styles__dialog___2qWmG>.styles__icon___3Dpcp>i{left:0;right:0;margin:auto}.styles__dialog___2qWmG>.styles__title___29TYh{font-size:20px;font-weight:700;color:#e4007f;margin:40px 5px 10px}.styles__dialog___2qWmG>.styles__action___1kuQ9{left:0;right:0;margin:30px auto 0}.styles__dialog___2qWmG>.styles__action___1kuQ9>button{width:150px;margin:5px 0}", ""]);

// exports
exports.locals = {
	"dialog": "styles__dialog___2qWmG",
	"icon": "styles__icon___3Dpcp",
	"title": "styles__title___29TYh",
	"action": "styles__action___1kuQ9"
};