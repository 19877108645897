import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';

import styles from './styles.css';

const PageTitle = ({ className, title, subtitle }) => (
	<div className={classnames(className, styles.title)}>
		<h2 className={styles.main}>{title}</h2>
		<p className={styles.sub}>{subtitle}</p>
	</div>
);

PageTitle.propTypes = {
	title: string,
	subtitle: string,
};

PageTitle.defaultProps = {
	title: '',
	subtitle: '',
};

export default PageTitle;
