/**
 * LoadingImage.react.js
 *
 * A loading image, copied from https://github.com/tobiasahlin/SpinKit
 *
 */

import React from 'react';

// Since this component doesn't need any state, make it a stateless component
const LoadingImage = () => (
	<div className="load-fading-img">
		<div className="load-img0 load-img" />
		<div className="load-img1 load-img" />
		<div className="load-img2 load-img" />
		<div className="load-img3 load-img" />
		<div className="load-img4 load-img" />
		<div className="load-img5 load-img" />
		<div className="load-img6 load-img" />
		<div className="load-img7 load-img" />
		<div className="load-img8 load-img" />
		<div className="load-img9 load-img" />
		<div className="load-img10 load-img" />
		<div className="load-img11 load-img" />
		<div className="load-img12 load-img" />
		<div className="load-img13 load-img" />
		<div className="load-img14 load-img" />
		<div className="load-img15 load-img" />
		<div className="load-img16 load-img" />
		<div className="load-img17 load-img" />
		<div className="load-img18 load-img" />
		<div className="load-img19 load-img" />
		<div className="load-img20 load-img" />
	</div>
);

export default LoadingImage;
