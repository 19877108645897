import React from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

const ButtonLink = ({
	className,
	type = 'internal',
    transparent,
	children,
	filled,
    inverse,
    disabled,
	href,
	onClick = () => {},
}) => {
	if (type === 'external') {
		return (
			<a
				className={classnames('button link-button', className, {
					filled,
					transparent,
					inverse,
					disabled,
				})}
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				onClick={e => {
					if (!disabled) {
						onClick(e);
					}
				}}
			>
				{children}
			</a>
		);
	}

	return (
		<Link
			className={classnames('button link-button', className, {
				filled,
				transparent,
				inverse,
				disabled,
			})}
			to={href}
			onClick={e => {
				if (!disabled) {
					onClick(e);
				}
			}}
		>
			{children}
		</Link>
	);
};

export default ButtonLink;
