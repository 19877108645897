export const SET_TARGET = 'SET_TARGET_ORDER';
export const SET_LIST = 'SET_ORDER_LIST';
export const SET_QUERY = 'SET_QUERY';
export const SET_STATUS = 'SET_ORDER_STATUS';
export const SET_STORE = 'SET_ORDER_STORE';

export const PAYMENT_MAP = {
	COMPANYMONTHLY: '月結匯公司',
	PERSONALMONTHLY: '月結匯私人',
	MAPLECASH: '便利帶代收',
	TCATCASH: '黑貓代收',
	SFCASH: '順豐代收',
	CASH: '現金',
	SHIPPINGINVOICE: '貨運開發票',
	CHECKINVOICE: '支票開發票',
};

export const DELIVERY_MAP = {
	MAPLECASH: '便利帶到付',
	MAPLE: '便利帶宅配',
	TCATCASH: '黑貓到付',
	TCAT: '黑貓宅配',
	SFCASH: '順豐到付',
	SF: '順豐宅配',
	POST: '郵局',
	PICKUP: '自取',
};
