import { combineReducers } from 'redux';

import { utils } from './utils';
import { modal } from './modal';

import { admin } from './admin';
import { category } from './category';
import { film } from './film';
import { order } from './order';
import { store } from './store';
import { storeClass } from './storeClass';

export default combineReducers({
	utils,
	modal,
	admin,
	film,
	category,
	order,
	store,
	storeClass,
});
