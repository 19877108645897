import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const LoginPage = Loadable({
	loader() {
		return import('react/components/Account/LoginPage').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const ResetPage = Loadable({
	loader() {
		return import('react/components/Account/PasswordResetPage').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const ConfirmPage = Loadable({
	loader() {
		return import('react/components/Account/ConfirmLogin').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const AdminListPage = Loadable({
	loader() {
		return import('react/components/Pages/Admin').then(object => object.default);
	},
	loading: LoadingDimmer,
});
