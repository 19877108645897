/**
 * PopupModal.react.js
 *
 * A component of alert dialog
 */

import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './styles.css';

class PopupModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shown: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { modalVisible: nextVisible } = nextProps;
		if (!this.state.shown && nextVisible) {
			this.setState({ shown: true });
		}
	}

	render() {
		const { modalVisible, close = () => {}, children } = this.props;
		let modalClasses = '';
		if (this.state.shown) {
			modalClasses = modalVisible ? 'visible' : 'hidden';
		}

		return (
			<div className={classnames('modal', styles.popupModal, modalClasses)} onClick={close}>
				{children}
			</div>
		);
	}
}

export default PopupModal;
