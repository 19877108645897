
import objectAssign from 'object.assign';

import {
	SENDING_REQUEST,
	LOADING_REQUEST,
	OPEN_ALERT,
	CLOSE_ALERT,
} from '../constants/utils';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	alert: {
		active: false,
		type: '',
		message: '',
	},
	currentlySending: false,
	currentlyLoading: false,
};

// Takes care of changing the application state
export function utils(state = initialState, action) {
	switch (action.type) {
		case SENDING_REQUEST:
			return assign({}, state, {
				currentlySending: action.sending,
			});
		case LOADING_REQUEST:
			return assign({}, state, {
				currentlyLoading: action.loading,
			});
		case OPEN_ALERT:
			return assign({}, state, {
				alert: {
					active: true,
					type: action.alert.type,
					message: action.alert.message,
				},
			});
		case CLOSE_ALERT:
			return assign({}, state, {
				alert: {
					active: false,
					type: state.alert.type,
					message: state.alert.message,
				},
			});
		default:
			return state;
	}
}
