/**
 *
 * LoadingDimmer.react.js
 *
 * This component renders container of common content
 *
 */

import React from 'react';
import LoadingImage from './LoadingImage.react';

const LoadingDimmer = () => (
	<div className="loading-wrapper">
		<LoadingImage />
	</div>
);

export default LoadingDimmer;
