import utilsReq from '../request/utils';


// download function
const download = (content, filename, mime) => {
	const newMime = mime == null ? 'text/csv' : mime;

	const blob = new Blob([content], { encoding: 'UTF-8', type: newMime });
	const a = document.createElement('a');
	a.download = filename;
	a.href = window.URL.createObjectURL(blob);
	a.dataset.downloadurl = [newMime, a.download, a.href].join(':');

	const e = document.createEvent('MouseEvents');
	e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false,
		false, false, 0, null);
	return a.dispatchEvent(e);
};
/**
 * download CSV file
 */
export const downloadCSV = (fileName, titleRow, rows) => {
	let csv = `${titleRow.map(JSON.stringify.bind(JSON)).join(',')}\n`;

	rows.forEach(row => {
		csv += `${row.join(',')}\n`;
	});

	download(csv, fileName, 'text/csv');
};

export const b64toBlob = (b64Data, contentTypeArg, sliceSizeArg) => {
	// function taken from http://stackoverflow.com/a/16245768/2591950
	// author Jeremy Banks http://stackoverflow.com/users/1114/jeremy-banks
	const contentType = contentTypeArg || '';
	const sliceSize = sliceSizeArg || 512;

	const byteCharacters = window.atob(b64Data);
	const byteArrays = [];

	let offset;
	for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		let i;
		for (i = 0; i < slice.length; i += 1) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new window.Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new window.Blob(byteArrays, {
		type: contentType,
	});
};

export const downloadExcel = (fileName, titleRow, rows) => {
	const postData = {
		fileName,
		titles: titleRow,
		rows,
	};
	return utilsReq.exportXlsx(postData);
};

export const downloadCanvas = (dataurl, filename) => {
	const a = document.createElement('a');
	a.download = filename;
	a.href = dataurl;

	const e = document.createEvent('MouseEvents');
	e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false,
		false, false, 0, null);
	return a.dispatchEvent(e);
};
