import React from 'react';
import classnames from 'classnames';

import { useBoolean } from 'react/utils/hook';

import ToolBar from 'react/components/Utils/ToolBar';
import Toggle from 'react/components/Utils/Input/Toggle';
import Dropdown from 'react/components/Utils/Input/Dropdown.react';
import ExportButton from 'react/components/Utils/Griddle/ExportButton';
import PopupModal from 'react/components/Utils/Modal/PopupModal';
import ConfirmDialog from 'react/components/Utils/Dialog/ConfirmDialog';

import StatusOptions from '../StatusOptions';

import styles from './styles.css';

const OrderToolBar = ({
	auth,
	selected,
	size,
	status,
	setSize,
	setStatus,
	deleteSelect,
	changeSelect,
	exportStickers,
	exportSelect,
	selectedAll,
	onSelectAll,
	onExport,
}) => {
	const [stickerModal, { setTrue: openStickerAlert, setFalse: closeStickerAlert }] = useBoolean();

	return (
		<ToolBar className={styles.toolbar}>
			<div className={styles.tools}>
				<Toggle
					htmlFor="order-select-all"
					defaultValue={selectedAll}
					onClose={() => onSelectAll(false)}
					onOpen={() => onSelectAll(true)}
					close="&#160;&#160;"
					open="&#10004;"
					small
					button
				/>
				<p>全選</p>
				<span />
				<Dropdown
					className={styles.drop}
					name="status"
					value={status}
					options={['全部狀態', '已完成', '生產製作中', '新訂單']}
					onChange={setStatus}
				/>
				<span />
				<p>顯示</p>
				<Dropdown
					className={styles.size}
					name="size"
					value={size}
					options={['10', '25', '50', '75', '100']}
					onChange={setSize}
				/>
				<p>筆</p>
				<span />
				{auth < 4 &&
					<button
						className={classnames(styles.btn, styles.del, {
							[styles.disable]: !selected,
						})}
						onClick={selected ? deleteSelect : () => { }}
					>
						刪除選擇 <i className="trash alternate outline icon" />
					</button>
				}
				{auth < 2 &&
					<StatusOptions
						className={classnames(styles.btn, {
							[styles.disable]: !selected,
						})}
						onSelect={changeSelect}
						disabled={!selected}
					>
						變更選擇
					</StatusOptions>
				}
				<ExportButton className={styles.btnExport} exportGriddle={onExport} />
				{auth < 3 &&
					<button
						className={classnames(styles.btn, {
							[styles.disable]: !selected,
						})}
						onClick={() => openStickerAlert()}
						disabled={!selected}
					>
						生產貼紙 <i className="paste icon" />
					</button>
				}
				{auth < 3 &&
					<button
						className={classnames(styles.btn, {
							[styles.disable]: !selected,
						})}
						onClick={selected ? exportSelect : () => { }}
						disabled={!selected}
					>
						下載出貨單 <i className="paste icon" />
					</button>
				}
			</div>
			<PopupModal modalVisible={stickerModal} close={closeStickerAlert}>
				<ConfirmDialog
					icon="exclamation"
					header="確定要下載生產貼紙嗎"
					description="您確定要下載生產貼紙嗎，此動作會將所選的訂單狀態改為生產製作中。"
					deny={closeStickerAlert}
					approve={() => {
						exportStickers();
						closeStickerAlert();
					}}
				/>
			</PopupModal>
		</ToolBar>
	);
};

export default OrderToolBar;
