exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__dividend___NcekX{border:1px solid #eee;margin:3px 0}", ""]);

// exports
exports.locals = {
	"dividend": "styles__dividend___NcekX"
};