/**
 *
 * ExportButton.react.js
 *
 * This component renders alert card for one information
 *
 */

import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './styles.css';

class ExportButton extends Component {
	constructor(props) {
		super(props);
		this._handleOutsideClick = this._handleOutsideClick.bind(this);
		this._dropdown = this._dropdown.bind(this);

		this.state = {
			dropdown: false,
		};
	}

	_handleOutsideClick(e) {
		// ignore clicks on the component itself
		if (!this.ref.contains(e.target)) {
			this.setState({ dropdown: false });
			document.removeEventListener('click', this._handleOutsideClick, false);
		}
	}

	_dropdown() {
		if (!this.state.dropdown) {
			// attach/remove event handler
			document.addEventListener('click', this._handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this._handleOutsideClick, false);
		}

		this.setState({ dropdown: !this.state.dropdown });
	}

	render() {
		const { className, exportGriddle } = this.props;
		return (
			<div className={classnames(styles.dropbutton, className)} ref={n => { this.ref = n; }}>
				<button
					className={styles.btn}
					onClick={() => this._dropdown()}
				>
					<i className="download icon" />
				</button>
				{
					this.state.dropdown
					? <div className={styles.dropdown}>
						<a className={styles.item} onClick={() => exportGriddle('csv')}>CSV</a>
						<a className={styles.item} onClick={() => exportGriddle('excel')}>MS Excel</a>
					</div> : null
				}
			</div>
		);
	}
}

export default ExportButton;
