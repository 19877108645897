/*
 * Dashboard
 *
 * This is the first thing admin sees.
 * Member count,
 * Route: /
 *
 */

import React from 'react';
import { connect } from 'react-redux';

import auth from 'react/utils/auth';

import PageTitle from 'react/components/Utils/PageTitle';
import InformationCard from 'react/components/Utils/InformationCard';
import Order from 'react/components/Pages/Order';
import { statusTranslate } from 'react/components/Pages/Order/List/StatusOptions';

import styles from './styles.css';

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const Dashboard = ({
	authClass, newOrderCount, totalOrderCount, totalCount, totalSumup, commissionSumup,
}) => (
	<div className="main-section">
		<PageTitle title="訂單總覽" subtitle="檢視所有的訂單" />
		<div className={styles.status}>
			<InformationCard
				icon="clipboard check bacdi-icon"
				number={newOrderCount}
				topic="新訂單"
			/>
			<InformationCard
				icon="clipboard list bacdi-icon"
				number={totalOrderCount}
				topic="訂單總數"
			/>
			{authClass !== '4' && authClass !== '2' &&
				<InformationCard
					icon="archive bacdi-icon"
					number={numberWithCommas(totalCount)}
					topic="篩選商品總數"
				/>
			}
			{authClass !== '4' && authClass !== '2' &&
				<InformationCard
					icon="dollar sign bacdi-icon"
					number={numberWithCommas(Math.ceil(totalSumup))}
					topic="篩選總金額"
				/>
			}
			{(authClass < 1 || (authClass === '3' && auth.iam())) &&
				<InformationCard
					icon="money bill alternate bacdi-icon"
					number={numberWithCommas(commissionSumup)}
					topic="篩選總元哥"
				/>
			}
		</div>
		{auth.loggedIn() && <Order className={styles.list} />}
	</div>
);

function mapStateToProps({ order, admin }) {
	return {
		newOrderCount: order.list.filter(o => o.status === 'NEW').length,
		totalOrderCount: order.list.length,
		totalSumup: order.list
			.filter(o => (
				(order.status === '全部狀態' || statusTranslate(o.status) === order.status) &&
				(order.store === '全部店家' || o.store.name === order.store)
			)).reduce((r, o) => r + (o.totalTax || o.total), 0),
		totalCount: order.list
			.filter(o => (
				(order.status === '全部狀態' || statusTranslate(o.status) === order.status) &&
				(order.store === '全部店家' || o.store.name === order.store)
			)).reduce((r, o) => r + (o.items.reduce((q, it) => q + it.quantity, 0)), 0),
		commissionSumup: order.list
			.filter(o => (
				(order.status === '全部狀態' || statusTranslate(o.status) === order.status) &&
				(order.store === '全部店家' || o.store.name === order.store)
			)).reduce((r, o) => r + (o.commission || 0), 0),
		authClass: admin.auth,
	};
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Dashboard);
