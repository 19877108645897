import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';

import routePath from 'react/constants/path';
import ButtonLink from 'react/components/Utils/Button/ButtonLink.react';
import SideSubMenu from 'react/components/DashboardNav/SideSubMenu';
import UserMenu from 'react/components/DashboardNav/UserMenu';
import logoImg from 'images/logo.png';

const AuthNavComponent = ({ auth, selected, onItemClick }) => (
	<div className="menu">
		<UserMenu />
		<div className="slide-btn">
			<ButtonLink href={routePath.orderCreate}>立即下單</ButtonLink>
		</div>
		<SideSubMenu
			title="總覽"
			links={routePath.root}
			icon="dashboard icon"
			selected={selected}
			onClick={onItemClick}
		/>
		{
			auth < 1 &&
			<SideSubMenu
				title="帳號管理"
				links={routePath.adminList}
				icon="user icon"
				selected={selected}
				onClick={onItemClick}
			/>
		}
		{
			auth < 2 &&
			<SideSubMenu
				title="商品類別管理"
				links={routePath.categoryRoot}
				icon="mobile alternate icon"
				selected={selected}
				onClick={onItemClick}
			/>
		}
		{
			auth < 2 &&
			<SideSubMenu
				title="商品加工或模料與設計"
				links={routePath.filmRoot}
				icon="gem icon"
				selected={selected}
				onClick={onItemClick}
			/>
		}
		{
			auth < 2 &&
			<SideSubMenu
				title="店家管理"
				links={routePath.storeRoot}
				icon="shopping bag icon"
				selected={selected}
				onClick={onItemClick}
			/>
		}
		{
			auth < 2 &&
			<SideSubMenu
				title="店家分類新增"
				links={routePath.storeClass}
				icon="clipboard list icon"
				selected={selected}
				onClick={onItemClick}
			/>
		}
	</div>
);

class DashboardSideNav extends Component {
	constructor(props) {
		super(props);
		this._sidebarDropdown = this._sidebarDropdown.bind(this);

		this.state = {
			selected: '',
		};
	}

	_sidebarDropdown(selected) {
		this.setState({ selected });
	}

	render() {
		const { show, onToggleNav, loggedIn, auth } = this.props;

		if (!show) {
			return (
				<button className="side_bar_hamburger" onClick={onToggleNav}>
					<i className="ui bars icon" />
				</button>
			);
		}
		return (
			<div className="side_bar" >
				<div className="logo" >
					<button className="side_bar_hamburger" onClick={onToggleNav}>
						<i className="ui bars icon" />
					</button>
					<img src={logoImg} alt="cms logo" />
				</div>
				{loggedIn
					? <AuthNavComponent
						auth={auth}
						selected={this.state.selected}
						onItemClick={this._sidebarDropdown}
					/>
					: (
						<div className="menu">
							<SideSubMenu
								title={`Login ${this.context.name}`}
								links={routePath.login}
								icon="user icon"
								selected=""
								onClick={() => { }}
							/>
						</div>
					)}
			</div>
		);
	}
}

DashboardSideNav.contextTypes = {
	name: string,
};


function mapStateToProps({ admin: { loggedIn, auth } }) {
	return { loggedIn, auth };
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(DashboardSideNav);
