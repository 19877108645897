import React from 'react';
import classnames from 'classnames';

const CircleButton = ({ className, transparent, children, type, ...props }) => (
	<div
		className={classnames('circle-button', className, {
			transparent,
		})}
		{...props}
	>
		<button type={type}>
			<div className="button-child">
				{children}
			</div>
		</button>
	</div>
);

export default CircleButton;
