import objectAssign from 'object.assign';
import { DOMAIN } from 'config.js';

import {
	SET_AUTH,
	SET_AUTH_NAME,
	SET_AUTHLEVEL,
	SET_TARGET,
	SET_ADMIN_LIST,
	SET_LOGIN_CONFIRM,
} from 'react/constants/admin';
import routePath from 'react/constants/path';
import authUtils from 'react/utils/auth';
import adminReq from 'react/request/admin';

import { updateStoreAccount } from './store';
import { anyElementsEmpty, requestNoted, sendingRequest, forwardTo } from './utils';

// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

const loginFormCss = '.loginForm';
const resetFormCss = '.resetPasswordForm';

/**
 * Sets the authentication state of the application
 * @param {boolean} newState True means a user is logged in, false means no user is logged in
 */
export function setAuthState(newState) {
	return { type: SET_AUTH, newState };
}
/**
 * Sets the authentication state of the application
 * @param {boolean} newState True means a user is logged in, false means no user is logged in
 */
export function setAuthLevelState(newState) {
	return { type: SET_AUTHLEVEL, newState };
}

export function setAuthNameState(newState) {
	return { type: SET_AUTH_NAME, newState };
}


export function setTargetAdmin(admin) {
	return { type: SET_TARGET, admin };
}

export function setAdminList(newState) {
	return { type: SET_ADMIN_LIST, newState };
}

export function setConfirmText(newState) {
	return { type: SET_LOGIN_CONFIRM, newState };
}


/**
 * Logs an user in
 * @param  {string} email The email of the user to be logged in
 * @param  {string} password The password of the user to be logged in
 */
export function login(username, password) {
	return async dispatch => {
		// Show the loading indicator, hide the last error
		dispatch(sendingRequest(true));

		// If no username or password was specified, throw a field-missing error
		if (anyElementsEmpty({ username, password })) {
			requestNoted(loginFormCss, {
				type: 'field-missing',
			});
			dispatch(sendingRequest(false));
			return;
		}

		const { success, errType } = await adminReq.login(username, password);
		dispatch(sendingRequest(false));
		if (success === true) {
			requestNoted(loginFormCss, {
				type: 'upload-success',
			});
		} else {
			requestNoted(loginFormCss, errType);
		}
	};
}

export function confirmLogin(hash) {
	return async dispatch => {
		const { success } = await adminReq.confirmlogin(hash);
		dispatch(sendingRequest(false));
		if (success === true) {
			dispatch(setAuthState(success));
			dispatch(setAuthLevelState(authUtils.auth()));
			dispatch(setAuthNameState(authUtils.name()));
			dispatch(forwardTo(routePath.root));
		} else {
			dispatch(setConfirmText('驗證有誤，請重新登入。'));
		}
	};
}

/**
 * Change password
 * @param  {string} name The name of the user to be logged in
 * @param  {string} password The password of the user to be logged in
 */
export function resetPassword(newPassword, newPasswordConfirm) {
	return async dispatch => {
		// Show the loading indicator, hide the last error
		dispatch(sendingRequest(true));

		// If no username or password was specified, throw a field-missing error
		if (newPassword !== newPasswordConfirm) {
			requestNoted(resetFormCss, {
				type: 'password-not-match',
			});
			dispatch(sendingRequest(false));
			return;
		}

		// If no new password or new password confirm was specified, throw a field-missing error
		if (anyElementsEmpty({ newPassword, newPasswordConfirm })) {
			requestNoted(resetFormCss, {
				type: 'field-missing',
			});
			dispatch(sendingRequest(false));
			return;
		}

		const { success, err } = await adminReq.resetPassword(newPassword, newPasswordConfirm);
		dispatch(sendingRequest(false));
		if (success === true) {
			requestNoted(resetFormCss, {
				type: 'upload-success',
			});
		} else {
			requestNoted(resetFormCss, err);
		}
	};
}

/**
 * Logs the current user out
 */
export function logout() {
	return async dispatch => {
		dispatch(sendingRequest(true));

		const { success, err } = await adminReq.logout();
		if (success === true) {
			dispatch(sendingRequest(false));
			dispatch(setAuthState(false));
			dispatch(setAuthLevelState(100));
			dispatch(forwardTo(routePath.login));
		} else {
			requestNoted(err);
			dispatch(sendingRequest(false));
		}
	};
}

export function fetchAdminList() {
	return async dispatch => {
		dispatch(sendingRequest(true));

		const { list } = await adminReq.fetchList();
		dispatch(setAdminList(list));
		dispatch(sendingRequest(false));
	};
}

export function fetchSalesList() {
	return async dispatch => {
		dispatch(sendingRequest(true));

		const { list } = await adminReq.fetchAuthList(3);
		dispatch(setAdminList(list));
		dispatch(sendingRequest(false));
	};
}

export function createAdmin(admin) {
	return async dispatch => {
		dispatch(sendingRequest(true));

		const { success } = await adminReq.create(admin);
		if (success) {
			dispatch(fetchAdminList());
		}
		return dispatch(sendingRequest(false));
	};
}

export function createStoreAdmin(admin) {
	return async (dispatch, getState) => {
		dispatch(sendingRequest(true));

		const { store: { target } } = getState();
		const { success, adminProfile } = await adminReq.create(admin);

		if (success) {
			const newState = assign(target, {
				account: adminProfile.id,
			});

			dispatch(updateStoreAccount(newState));
			dispatch(forwardTo(routePath.adminList));
		}
		return dispatch(sendingRequest(false));
	};
}

export function activateAdmin(id) {
	return async dispatch => {
		dispatch(sendingRequest(true));

		const { success, hash } = await adminReq.activate(id);
		if (success) {
			return window.open(`${DOMAIN}/auth/activate?h=${hash}`, '_blank');
		}
		return dispatch(sendingRequest(false));
	};
}

export function removeAdmin(id) {
	return async dispatch => {
		const { success } = await adminReq.delete(id);
		if (success) {
			dispatch(fetchAdminList());
		}
	};
}
