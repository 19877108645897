exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__status___1Rl-K{width:100%;height:100px;padding:10px;border:1px solid $border-color;box-shadow:0 0 5px 0 rgba(0,0,0,.07)}@media screen and (max-width:719px){.styles__status___1Rl-K{padding:5px;height:auto}}.styles__list___1J6H9{width:100%;margin-top:30px}", ""]);

// exports
exports.locals = {
	"status": "styles__status___1Rl-K",
	"list": "styles__list___1J6H9"
};