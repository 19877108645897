import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const FilmPage = Loadable({
	loader() {
		return import('react/components/Pages/Film').then(object => object.default);
	},
	loading: LoadingDimmer,
});
