
import objectAssign from 'object.assign';

import {
	SET_TARGET,
	SET_LIST,
	SET_STATUS,
	SET_STORE,
	SET_QUERY,
} from 'react/constants/order';
// Object.assign is not yet fully supported in all browsers, so we fallback to
// a polyfill
const assign = Object.assign || objectAssign;

// The initial application state
const initialState = {
	list: [],
	status: '全部狀態',
	store: '全部店家',
	target: {
		_id: '',
		creator: '',
		serial: '',
		batch: '',
		status: '',
		store: '',
		items: [],
		discount: '',
		discountTax: '',
		price: '',
		total: '',
		totalTax: '',
		note: '',
		shipment: {},
		payment: {},
		invoicing: false,
		invoice: {},
		costType: 'BASED',
	},
	query: {
		category: '',
		start: '',
		end: '',
		sales: '',
	},
};

// Takes care of changing the application state
export function order(state = initialState, action) {
	switch (action.type) {
		case SET_TARGET:
			return assign({}, state, {
				target: action.order,
			});
		case SET_LIST:
			return assign({}, state, {
				list: action.orders,
			});
		case SET_STATUS:
			return assign({}, state, {
				status: action.status,
			});
		case SET_STORE:
			return assign({}, state, {
				store: action.store,
			});
		case SET_QUERY:
			return assign({}, state, {
				query: action.q,
			});
		default:
			return state;
	}
}
