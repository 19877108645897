import auth from 'react/utils/auth';
import { easyFetch, bodyFetch, authEasyFetch, authBodyFetch } from './endpoint';

const windowsLocalStorage = window.localStorage;

const adminRequest = {
	async login(email, password) {
		if (auth.loggedIn()) {
			return Promise.resolve({ success: true });
		}
		// Post a request
		return bodyFetch('/api/admin/logon', 'POST', { email, password })
			.then(() => Promise.resolve({ success: true })).catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}

				let resType;
				switch (err.message) {
					case 100:
						resType = { type: 'user-doesnt-exist' };
						break;
					case 102:
						resType = { type: 'wrong-password' };
						break;
					case 109:
						resType = { type: 'not-authorized' };
						break;
					default:
						resType = { type: 'failed' };
						break;
				}
				return Promise.resolve({ success: false, errType: resType });
			});
	},
	async confirmlogin(hash) {
		if (auth.loggedIn()) {
			return Promise.resolve({ success: true });
		}
		// Post a request
		return easyFetch(`/api/admin/confirmlogon?h=${encodeURIComponent(hash)}`, 'GET')
			.then(({ token, adminProfile }) => {
				windowsLocalStorage.admintoken = token;
				windowsLocalStorage.id = adminProfile.id;
				windowsLocalStorage.email = adminProfile.email;
				windowsLocalStorage.auth = adminProfile.auth;
				return Promise.resolve({ success: true });
			}).catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false, errType: { type: 'failed' } });
			});
	},
	async resetPassword(newPassword, newPasswordConfirm) {
		try {
			await authBodyFetch('/api/admin/resetPassword', 'POST', { newPassword, newPasswordConfirm });
			return { success: true };
		} catch (err) {
			return { success: false, error: err.error };
		}
	},
	async logout() {
		return easyFetch('/api/admin/logoff', 'GET').then(() => {
			windowsLocalStorage.removeItem('admintoken');
			return Promise.resolve({ success: true });
		}).catch(err => {
			if (err.status !== 200) {
				return auth.errorProcessing(err.status);
			}
			return Promise.resolve({ success: false });
		});
	},

	async create(adm) {
		return authBodyFetch('/api/admin/create', 'POST', adm)
			.then(({ adminProfile }) => Promise.resolve({ success: true, adminProfile }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	},

	async activate(id) {
		return authEasyFetch(`/api/admin/getActivate/${id}`, 'GET')
			.then(({ hash }) => Promise.resolve({ success: true, hash }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	},

	async delete(id) {
		return authEasyFetch(`/api/admin/${id}`, 'DELETE')
			.then(() => Promise.resolve({ success: true }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	},

	async fetchList() {
		return authEasyFetch('/api/admin/list', 'GET')
			.then(({ list }) => Promise.resolve({ success: true, list }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	},

	async fetchAuthList(level) {
		return authEasyFetch(`/api/admin/list?auth=${level}`, 'GET')
			.then(({ list }) => Promise.resolve({ success: true, list }))
			.catch(err => {
				if (err.status !== 200) {
					return auth.errorProcessing(err.status);
				}
				return Promise.resolve({ success: false });
			});
	},
};

export default adminRequest;
