
import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk-fsa';

import reducer from './reducers';

// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
	const { createLogger } = require('redux-logger'); // eslint-disable-line global-require
	middlewares.push(
		createLogger(),
	);
}

const createStoreWithMiddleware
	= compose(applyMiddleware(...middlewares))(createStore);

export default function configureStore() {
	const store = createStoreWithMiddleware(reducer);

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const nextReducers = require('./reducers'); // eslint-disable-line global-require
			store.replaceReducer(nextReducers);
		});
	}
	return store;
}
