import React, { useEffect, useRef } from 'react';

import { useBoolean } from 'react/utils/hook';

import ToolBar from 'react/components/Utils/ToolBar';
import Dropdown from 'react/components/Utils/Input/Dropdown.react';
import StoreSelectorModal from 'react/components/Utils/Modal/StoreSelectorModal';

import styles from './styles.css';

const OrderFilterBar = ({
	auth,
	store,
	storeList,
	sales,
	salesList,
	product,
	categoryList,
	time,
	setStore,
	setSales,
	setProduct,
	setTime,
}) => {
	const refStore = useRef(null);
	const [selectorShow, { setTrue: openSelector, setFalse: closeSelector }] = useBoolean();
	const d = new Date();
	const productList = categoryList.map(c => c.name);
	const newSalesList = salesList.map(s => s.name);

	useEffect(() => {
		if (refStore.current && selectorShow) {
			refStore.current.focus();
		}
	});

	const focusInChildren = (relatedTarget, currentTarget) => {
		if (relatedTarget === null) {
			return false;
		}

		if (relatedTarget === currentTarget) {
			return true;
		}

		return focusInChildren(relatedTarget.parentNode, currentTarget);
	};

	const onBlur = ({ relatedTarget }) => {
		if (!focusInChildren(relatedTarget, refStore.current)) {
			closeSelector();
		}
	};

	const TriangleIcon = ({ dropdown }) => {
		if (dropdown) {
			return (
				<a className="ui trangle-i">
					<i className={'angle up icon'} />
				</a>
			);
		}
		return (
			<a className="ui trangle-i">
				<i className={'angle down icon'} />
			</a>
		);
	};

	return (
		<ToolBar className={styles.toolbar}>
			<div className={styles.tools}>
				{auth < 4 &&
					<div ref={refStore} onBlur={onBlur}>
						<div
							className={styles.selector}
							onClick={selectorShow ? closeSelector : openSelector}
							role="button"
							tabIndex="0"
						>
							<p>{store}</p>
							<TriangleIcon
								dropdown={selectorShow}
							/>
						</div>
						<StoreSelectorModal
							selectAll={{ name: '全部店家' }}
							modalVisible={selectorShow}
							stores={storeList}
							onSelect={s => {
								setStore(s.name);
								closeSelector();
							}}
						/>
					</div>
				}
				{auth < 4 && <span />}
				{auth < 4 &&
					<Dropdown
						className={styles.drop}
						name="sales"
						value={sales}
						options={['全部業務', ...newSalesList]}
						onChange={s => (s === '全部業務' ? setSales(s) : setSales(salesList.find(sl => sl.name === s)))}
					/>
				}
				{auth < 4 && <span />}
				<Dropdown
					className={styles.dropProd}
					name="product"
					value={product}
					options={['全部商品', ...productList]}
					onChange={setProduct}
				/>
				<span />
				<p>從</p>
				<input
					type="date"
					value={time.start}
					onChange={evt => setTime({ ...time, start: evt.target.value })}
				/>
				<p>到</p>
				<input
					type="date"
					max={`${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, 0)}-${(d.getDate()).toString().padStart(2, 0)}`}
					value={time.end}
					onChange={evt => setTime({ ...time, end: evt.target.value })}
				/>
			</div>
		</ToolBar>
	);
};

export default OrderFilterBar;
