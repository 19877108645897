exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__title___1SUFb{width:100%;margin:10px 0}.styles__title___1SUFb>.styles__main___2Kh4_{font-size:22px;font-weight:500;color:#333}.styles__title___1SUFb>.styles__sub___3QY41{font-size:13px;color:#777}", ""]);

// exports
exports.locals = {
	"title": "styles__title___1SUFb",
	"main": "styles__main___2Kh4_",
	"sub": "styles__sub___3QY41"
};