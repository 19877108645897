import { useState, useEffect, useRef } from 'react';

export const useMedia = () => {
	const phoneMedia = window.matchMedia('(max-width: 719px)');
	const tabletMedia = window.matchMedia('(max-width: 1199px) and (min-width: 720px)');
	const desktopMedia = window.matchMedia('(min-width: 1200px)');

	let defaultMedia = 'desktop';

	if (phoneMedia.matches) {
		defaultMedia = 'phone';
	}

	if (tabletMedia.matches) {
		defaultMedia = 'tablet';
	}

	const [media, setMedia] = useState(defaultMedia);

	const handleMediaChange = mediaName => mediaHandler => {
		if (mediaHandler.matches && mediaName !== media) {
			setMedia(mediaName);
		}
	};

	useEffect(() => {
		const phoneHandler = handleMediaChange('phone');
		const tabletHandler = handleMediaChange('tablet');
		const desktopHandler = handleMediaChange('desktop');

		phoneMedia.addListener(phoneHandler);
		tabletMedia.addListener(tabletHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			phoneMedia.removeListener(phoneHandler);
			tabletMedia.removeListener(tabletHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	}, [media]);

	return media;
};

// Handle the Dom event
export const useDom = eventHandler => {
	// Only subscribe/unsubscribe on mount/unmount lifecycle
	useEffect(() => {
		Object.keys(eventHandler).forEach(event => window.addEventListener(event, eventHandler[event]));

		return () => {
			Object.keys(eventHandler).forEach(event =>
				window.removeEventListener(event, eventHandler[event]),
			);
		};
	}, []);
};

export const useElement = ({
	defaultHeight,
	onUpdateOffsetTop = () => {},
	onUpdateHeight = () => {},
}) => {
	const [{ height, offsetTop }, setState] = useState({ height: defaultHeight, offsetTop: null });
	const refChild = useRef(null);

	const handleOffsetTop = () => {
		const node = refChild.current;
		const bodyRect = document.body.getBoundingClientRect();
		const elemRect = node.getBoundingClientRect();
		const newOffsetTop = elemRect.top - bodyRect.top;

		if (offsetTop !== newOffsetTop) {
			setState(prevState => ({ ...prevState, offsetTop: newOffsetTop }));

			onUpdateOffsetTop(newOffsetTop);
		}
	};

	const handleHeight = () => {
		const node = refChild.current;

		if (height !== node.offsetHeight && node.offsetHeight > 0) {
			// Update the component height
			setState(prevState => ({ ...prevState, height: node.offsetHeight }));

			onUpdateHeight(node.offsetHeight);
		}
	};

	const handleDomEvent = () => {
		if (refChild.current !== null && refChild.current.node !== null) {
			handleHeight();
			handleOffsetTop();
		}
	};

	const handleScroll = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	const handleResize = () => {
		window.requestAnimationFrame(handleDomEvent);
	};

	useDom({ scroll: handleScroll, resize: handleResize });

	useEffect(() => {
		let didUnsubscribe = false;

		const updateDomHandle = () => {
			if (didUnsubscribe) {
				return;
			}
			handleDomEvent();
		};

		// Setup initial value
		updateDomHandle();

		return () => {
			didUnsubscribe = true;
		};
	}, [height, offsetTop, refChild]);

	return { refChild, height, offsetTop };
};

export const useScroll = () => {
	const [scrollY, setState] = useState(window.scrollY);

	let ticking = false;

	const handleScroll = () => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				setState(window.scrollY);
				ticking = false;
			});
		}
		ticking = true;
	};

	useDom({ scroll: handleScroll });

	return scrollY;
};

export const useResize = () => {
	const { clientWidth, clientHeight } = document.documentElement;

	const [size, setState] = useState({ width: clientWidth, height: clientHeight });

	let ticking = false;

	const resizeEvent = () => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				setState({
					width: document.documentElement.clientWidth,
					height: document.documentElement.clientHeight,
				});
				ticking = false;
			});
		}
		ticking = true;
	};

	useDom({ resize: resizeEvent });

	return size;
};
