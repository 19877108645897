import Loadable from 'react-loadable';

import LoadingDimmer from 'react/components/Utils/LoadingDimmer';

export const CategoryPage = Loadable({
	loader() {
		return import('react/components/Pages/Category').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const CategoryCreate = Loadable({
	loader() {
		return import('react/components/Pages/Category/Create').then(object => object.default);
	},
	loading: LoadingDimmer,
});

export const CategoryEdit = Loadable({
	loader() {
		return import('react/components/Pages/Category/Edit').then(object => object.default);
	},
	loading: LoadingDimmer,
});
