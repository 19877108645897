import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { forwardTo } from 'react/actions/utils';

import {
	fetchOrderList,
	downloadOrder,
	removeOrder,
	changeOrderStatus,
	exportOrderStickers,
	queryOrder,
	updateGriddle,
	updateShipment,
	updatePaid,
	setOrderStatus,
	setOrderStore,
} from 'react/actions/order';
import { fetchStoreList } from 'react/actions/store';
import { fetchCategoryList } from 'react/actions/category';
import { fetchSalesList } from 'react/actions/admin';
import { useBoolean } from 'react/utils/hook';

import PopupModal from 'react/components/Utils/Modal/PopupModal';
import ConfirmDialog from 'react/components/Utils/Dialog/ConfirmDialog';
import { statusTranslate } from './List/StatusOptions';

import OrderList from './List';

import styles from './styles.css';

const useOrder = ({
	store,
	orderList,
	removeOrderids,
	changeStatus,
	exportStickers,
}) => {
	const [selected, setSelected] = useState([]);
	const [selectedAll, setSelectAll]
		= useState(new Array(Math.ceil(orderList.length / 25)).fill(false));
	const [size, setSize] = useState(25);
	const [curPage, setCurPage] = useState(1);
	const [targetIds, setTargetIds] = useState([]);
	const [removeModal, { setTrue: openRemoveAlert, setFalse: closeRemoveAlert }] = useBoolean();

	useEffect(() => {
		const falseArr = new Array(Math.ceil(orderList.length / size)).fill(false);
		setSelectAll(falseArr);
	}, [orderList.length, size]);

	const _callRemove = ids => {
		setTargetIds(ids);
		openRemoveAlert();
	};
	const _approveRemove = () => {
		removeOrderids(targetIds);
		setTargetIds([]);
		setSelected([]);
		closeRemoveAlert();
	};
	const _changeStatus = (ids, st) => {
		changeStatus(ids, st);
		setSelected([]);
	};
	const _callStickers = ids => {
		exportStickers(ids);
		setSelected([]);
	};
	const _onSelected = (id, value, selectArr) => {
		if (value) {
			setSelected([...selectArr, id]);
		} else {
			const idx = selectArr.findIndex(sid => sid === id);
			setSelected([...selectArr.slice(0, idx), ...selectArr.slice(idx + 1)]);
		}
	};
	const _onSelectAll = value => {
		const from = (curPage - 1) * size;
		const to = curPage * size;
		if (value) {
			const selectedOrder = orderList
				.filter(o => (
					(status === '全部狀態' || statusTranslate(o.status) === status) &&
					(store === '全部店家' || o.store.name === store)
				)).slice(from, to).map(o => o._id);
			setSelected([...selected, ...selectedOrder]);

			const newSelectAll = [...selectedAll];
			newSelectAll[curPage - 1] = true;
			setSelectAll(newSelectAll);
		} else {
			const removeIds = orderList
				.filter(o => (
					(status === '全部狀態' || statusTranslate(o.status) === status) &&
					(store === '全部店家' || o.store.name === store)
				)).slice(from, to).map(o => o._id);
			const newSelected = [...selected].filter(s => !removeIds.find(r => r === s));
			setSelected(newSelected);

			const newSelectAll = [...selectedAll];
			newSelectAll[curPage - 1] = false;
			setSelectAll(newSelectAll);
		}
	};

	return {
		size,
		setSize,
		curPage,
		setCurPage,
		selected,
		selectedAll,
		removeModal,
		closeRemoveAlert,
		_callRemove,
		_approveRemove,
		_changeStatus,
		_callStickers,
		_onSelected,
		_onSelectAll,
	};
};

const Order = ({
	orderList,
	storeList,
	categoryList,
	adminList,
	status,
	setStatus,
	store,
	setStore,
	fetchOrders,
	fetchStores,
	fetchSales,
	fetchCategories,
	downloadOrders,
	removeOrderids,
	changeStatus,
	exportStickers,
	goto,
	queryTargetOrder,
	update,
	updateShip,
	setPaid,
	auth,
}) => {
	useEffect(() => {
		fetchOrders();
		fetchStores();
		fetchCategories();
		if (auth < 2) fetchSales();
	}, []);

	const {
		size,
		setSize,
		curPage,
		setCurPage,
		selected,
		selectedAll,
		removeModal,
		closeRemoveAlert,
		_callRemove,
		_approveRemove,
		_changeStatus,
		_callStickers,
		_onSelected,
		_onSelectAll,
	} = useOrder({ store, orderList, removeOrderids, changeStatus, exportStickers });

	return (
		<div className={styles.content}>
			<OrderList
				stores={storeList}
				salesList={adminList}
				categories={categoryList}
				orders={orderList}
				size={size}
				setSize={setSize}
				status={status}
				setStatus={setStatus}
				store={store}
				setStore={setStore}
				curPage={curPage}
				setCurPage={setCurPage}
				selected={selected}
				selectedAll={selectedAll}
				onSelected={_onSelected}
				onSelectAll={_onSelectAll}
				callDownload={downloadOrders}
				callRemove={_callRemove}
				callChangeStatus={_changeStatus}
				callStickers={_callStickers}
				goto={goto}
				queryTargetOrder={queryTargetOrder}
				update={update}
				updateShip={updateShip}
				setPaid={setPaid}
				auth={auth}
			/>
			<PopupModal modalVisible={removeModal} close={closeRemoveAlert}>
				<ConfirmDialog
					icon="exclamation"
					header="確定刪除"
					description="您確定要刪除此訂單嗎？"
					deny={closeRemoveAlert}
					approve={_approveRemove}
				/>
			</PopupModal>
		</div>
	);
};

function mapStateToProps({
	order,
	store,
	category,
	admin,
}) {
	return {
		status: order.status,
		store: order.store,
		orderList: order.list,
		storeList: store.list,
		categoryList: category.list,
		adminList: admin.list,
		auth: admin.auth,
	};
}

function mapDispatchToProps(dispatch) {
	return ({
		goto: path => window.open(path, '_blank'), // dispatch(forwardTo(path)),
		fetchOrders: id => dispatch(fetchOrderList(id)),
		downloadOrders: ids => dispatch(downloadOrder(ids)),
		removeOrderids: ids => dispatch(removeOrder(ids)),
		changeStatus: (id, status) => dispatch(changeOrderStatus(id, status)),
		exportStickers: ids => dispatch(exportOrderStickers(ids)),
		fetchStores: () => dispatch(fetchStoreList()),
		fetchCategories: () => dispatch(fetchCategoryList()),
		queryTargetOrder: q => dispatch(queryOrder(q)),
		update: o => dispatch(updateGriddle(o)),
		updateShip: o => dispatch(updateShipment(o)),
		fetchSales: () => dispatch(fetchSalesList()),
		setPaid: o => dispatch(updatePaid(o)),
		setStatus: s => dispatch(setOrderStatus(s)),
		setStore: s => dispatch(setOrderStore(s)),
	});
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(Order);
