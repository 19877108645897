exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles__justify___3NBgf{-ms-flex-pack:justify;justify-content:space-between}.styles__item___ctDTs,.styles__justify___3NBgf{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.styles__item___ctDTs{margin:10px 0}.styles__item___ctDTs>.styles__info___3EogU{width:100%}.styles__infobox___KJ1Ap{padding:15px;border-radius:2.5px;border:1px solid #eee;margin:10px 0}.styles__viewer___2gPlK{position:relative;left:0;right:0;margin:auto;border:1px solid #eee;border-radius:2.5px;background-color:#fff;padding:1em 2em;width:600px;min-height:400px;max-height:550px;overflow-y:auto}.styles__viewer___2gPlK>.styles__sumup___1S2so{width:50%;margin-left:auto}", ""]);

// exports
exports.locals = {
	"justify": "styles__justify___3NBgf",
	"item": "styles__item___ctDTs",
	"info": "styles__info___3EogU",
	"infobox": "styles__infobox___KJ1Ap",
	"viewer": "styles__viewer___2gPlK",
	"sumup": "styles__sumup___1S2so"
};