import React from 'react';
import classnames from 'classnames';

import Dividend from 'react/components/Utils/Dividend';

import styles from './styles.css';

const OrderItem = ({ item }) => (
	<div className={styles.item}>
		<div className={styles.info}>
			<div className={styles.justify}>
				<p>{`【${item.category}】${item.brand}${item.model}-${item.film} ${item.design}`}</p>
				<p>x{item.quantity}</p>
			</div>
			<div className={styles.justify}>
				<p>{item.price}</p>
				<p className={styles.total}>total: {item.total.toFixed(2)}</p>
			</div>
		</div>
	</div>
);

const OrderInfo = ({ order }) => (
	<div className={styles.infobox}>
		<div className={styles.justify}>
			<p>店家名稱</p>
			<p>{order.store.name}</p>
		</div>
		<div className={styles.justify}>
			<p>電話</p>
			<p>{order.store.phone}</p>
		</div>
		<div className={styles.justify}>
			<p>地址</p>
			<p>{order.store.address}</p>
		</div>
		<div className={styles.justify}>
			<p>發票</p>
			<p>{(order.store.invoice && order.store.invoicing) ? order.store.invoice.title : ''}</p>
		</div>
	</div>
);

const OrderNote = ({ note }) => (
	<div className={styles.infobox}>
		<h6>客戶備註</h6>
		<p>{note}</p>
	</div>
);

const OrderSumup = ({ order }) => (
	<div className={styles.infobox}>
		{order.items.map(it => <OrderItem key={it._id} item={it} />)}
		<Dividend />
		<div className={styles.sumup}>
			<div className={styles.justify}>
				<p>商品總額</p>
				<p>{order.price}</p>
			</div>
			<div className={styles.justify}>
				<p>運費</p>
				<p>{order.shipment.price}</p>
			</div>
			<div className={styles.justify}>
				<p>訂單總金額</p>
				<p>{order.total}</p>
			</div>
		</div>
	</div>
);

const OrderViewer = ({ className, order }) => {
	if (order) {
		return (
			<div className={classnames(styles.viewer, className)} onClick={e => e.stopPropagation()}>
				<h2>訂單詳細資訊</h2>
				<br />
				<p>訂單編號：{order.serial}</p>
				<p>批號：{order.batch}</p>
				<OrderSumup order={order} />
				<OrderInfo order={order} />
				<OrderNote note={order.note} discountNote={order.discountNote} />
			</div>
		);
	}
	return null;
};

export default OrderViewer;
