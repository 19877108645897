import React, { Component } from 'react';
import classnames from 'classnames';
import { RowDefinition, ColumnDefinition } from 'griddle-react';

import Button from 'react/components/Utils/Button/Button.react';
import IsurpriceGriddle from 'react/components/Utils/Griddle/IsurpriceGriddle';

import styles from './styles.css';

class StoreSelectorModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shown: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { modalVisible: nextVisible } = nextProps;
		if (!this.state.shown && nextVisible) {
			this.setState({ shown: true });
		}
	}

	render() {
		const { modalVisible, stores, onSelect, selectAll } = this.props;
		let modalClasses = '';
		if (this.state.shown) {
			modalClasses = modalVisible ? styles.visible : styles.hidden;
		}

		const ColumnComponent = ({ value, click }) => {
			const data = this.props.stores.filter(s => s.name === value)[0];
			return (
				<div className={styles.columnComponent} onClick={() => click(data)}>
					{value}
				</div>
			);
		};

		return (
			<div
				className={classnames(styles.storeSelectorModal, modalClasses)}
				role="button"
				tabIndex="0"
			>
				{selectAll &&
					<Button
						className={styles.btn}
						small
						onClick={() => onSelect(selectAll)}
					>全部店家</Button>
				}
				<IsurpriceGriddle
					data={stores}
					size={1000}
					pagination={false}
				>
					<RowDefinition>
						<ColumnDefinition
							id="name"
							title="店家名稱"
							width={100}
							customComponent={ColumnComponent}
							extraData={{ click: s => onSelect(s) }}
						/>
					</RowDefinition>
				</IsurpriceGriddle>
			</div>
		);
	}
}

export default StoreSelectorModal;
